import React, {useEffect, useRef, useState} from 'react';
import StandardElementsPieChart from "./standardElementsPieChart";
import DOMPurify from "dompurify";
import {stripHtml} from "../../functions/stripHtml";
import getWithAuth from "../../context/getWithAuth";
import TraditionalMCScrambledPreview from "../../teacher/data/traditionalMCScrambledPreview";
import ImageMCScrambledPreview from "../../teacher/data/imageMCScrambledPreview";
import MatchingScrambledPreview from "../../teacher/data/matchingScrambledPreview";
import SortingScrambledPreview from "../../teacher/data/sortingScrambledPreview";
import Loader from "../../components/loader";

function QuestionPreviewModal({user, questionPreviewModal, setQuestionPreviewModal}) {
    const modalRef = useRef(null);
    const [questionContent, setQuestionContent] = useState(null);

    const fetchQuestionPreview = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/student/getQuestionPreview`, user.userAttributes['custom:school'], {questionTemplateId: questionPreviewModal})
                    .then(async r => {
                        setQuestionContent(r)
                    })
            } catch (error) {
                console.error('Error fetching question data:', error)
            }
        }
    }

    useEffect(() => {
        fetchQuestionPreview()
        }, [questionPreviewModal]
    );

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setQuestionPreviewModal]);
    if (!questionPreviewModal) {
        return null;
    }

    const onClose = () => {
        setQuestionPreviewModal(null)
    }

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };



    const handleClose = (e) => {
        if ( e.target.id === "wrapper4" ) onClose();
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-40"} id="wrapper4" onClick={handleClose}>
            <div className={"relative bg-dutch_white-200 m-4 rounded p-2 min-h-[40%]  max-h-[90%] min-w-[60%] max-w-[90%]"} ref={modalRef}>
                <button onClick={onClose} aria-label={'Close'}
                        className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff"
                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={'p-1 bg-columbia_blue-800 h-full'}>
                    {(questionContent) ?
                        (questionContent.type === "Traditional MC") ? <TraditionalMCScrambledPreview assessmentItem={questionContent} /> : (questionContent.type === "Image MC") ? <ImageMCScrambledPreview assessmentItem={questionContent} /> : (questionContent.type === "Matching") ? <MatchingScrambledPreview assessmentItem={questionContent} /> : (questionContent.type === "Sorting") ? <SortingScrambledPreview assessmentItem={questionContent} /> : <></>
                    : <Loader message={'Fetching Assessment Item Content'} />}
                </div>

            </div>
        </div>

    );
}

export default QuestionPreviewModal;