import React, {useEffect, useRef, useState} from 'react';
import StandardElementsPieChart from "./standardElementsPieChart";
import DOMPurify from "dompurify";
import {stripHtml} from "../../functions/stripHtml";
import QuestionPreviewModal from "./questionPreviewModal";

function StandardElementsModal({user, standardElementsModal, setStandardElementsModal, knowShowElements}) {
    const modalRef = useRef(null);
    const [questionPreviewModal, setQuestionPreviewModal] = useState(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setStandardElementsModal]);
    if (!standardElementsModal) {
        return null;
    }

    const onClose = () => {
        setStandardElementsModal(null)
    }


    let pieChartData = [
        {
            name: "Correct",
            value: (standardElementsModal.standardData.filter(entry => entry.type === 'Recall').length > 0) ? standardElementsModal.standardData.filter(entry => (entry.result === true || typeof entry.result === 'number' && entry.result === 0)).length + standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).filter(entry => entry.correct === true).length / standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).length : standardElementsModal.standardData.filter(entry => (entry.result === true || typeof entry.result === 'number' && entry.result === 0)).length,
            fill: "#538ea5"
        },
        {
            name: 'Pending',
            value: (standardElementsModal.standardData.filter(entry => entry.type === 'Recall').length > 0) ? standardElementsModal.standardData.filter(entry => (entry.result === 'pending')).length + standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).filter(entry => entry.correct === 'pending').length / standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).length : standardElementsModal.standardData.filter(entry => (entry.result === 'pending')).length,
            fill: "#8b8b8b"
        },
        {
            name: 'Incorrect',
            value: (standardElementsModal.standardData.filter(entry => entry.type === 'Recall').length > 0) ? standardElementsModal.standardData.filter(entry => (entry.result === false || typeof entry.result === 'number' && entry.result > 0)).length + standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).filter(entry => entry.correct === false).length / standardElementsModal.standardData.filter(entry => entry.type === 'Recall').flatMap(entry => entry.recallItems).map(entry => entry.active).length : standardElementsModal.standardData.filter(entry => (entry.result === false || typeof entry.result === 'number' && entry.result > 0)).length,
            fill: "#dd6e42"
        }
    ]

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };



    const handleClose = (e) => {
        if ( e.target.id === "wrapper3" ) onClose();
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper3" onClick={handleClose}>
            <div className={"relative bg-dutch_white-200 m-4 rounded p-2 h-[90%] w-[90%]"} ref={modalRef}>
                <button onClick={onClose} aria-label={'Close'}
                        className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff"
                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={'grid grid-cols-5 gap-2 min-h-fit h-[50%] overflow-y-auto overflow-x-hidden'}>
                    <div className={'col-span-3 bg-dutch_white-400 p-4 rounded'} tabIndex={0}
                         aria-label={`Standard Overview`}>
                        <div
                            aria-label={`${standardElementsModal.standard.title}: ${standardElementsModal.standard.text}`}
                            tabIndex={0}>
                            <p className={'font-bold text-lg p-2'}>{standardElementsModal.standard.title}</p>
                            <div className={'text-sm'}
                                 dangerouslySetInnerHTML={renderHtml(standardElementsModal.standard.text)}/>
                        </div>


                    </div>
                    <div className={'col-span-2 my-auto flex flex-col rounded bg-platinum-500'} tabIndex={0}
                         aria-label={'Results Distribution'}>
                        <p className={'text-columbia_blue-800'}>Results Distribution</p>
                        <StandardElementsPieChart data={pieChartData}/>
                    </div>
                </div>
                <div className={'grid md:grid-cols-3 md:grid-rows-1 sm:grid-rows-3 gap-2 mt-2 h-[45%]'}>
                    <div
                        className={"relative bg-columbia_blue-400 text-sm rounded text-columbia_blue-800 mb-2 cursor-black h-full overflow-y-auto"}
                        tabIndex={0} aria-label={'Recall Elements'}>
                        <p className={'font-bold text-lg'}>Recall</p>
                        {standardElementsModal.standardData.filter(item => item.type === 'Recall').map(item =>
                            <div className={'flex flex-col bg-columbia_blue-900 m-2 rounded'}>
                                <p className={'font-bold text-sm text-dutch_white-200'} tabIndex={0}
                                   aria-label={`Recall Set: ${item.title}`}>{item.title}</p>
                                <div className={'flex flex-wrap'}>
                                    {item.recallItems.map(entry => (entry.active.correct === true) ?
                                        <div
                                            aria-label={`${stripHtml(entry.prompt.content)} -- Correct`}
                                            tabIndex={0}
                                            className={'bg-columbia_blue-600 p-1 m-1 text-xs rounded shadow text-columbia_blue-900 px-2 py-1'}
                                            dangerouslySetInnerHTML={renderHtml(entry.prompt.content)}/>
                                        : (entry.active.correct === 'pending') ?
                                            <div
                                                aria-label={`${stripHtml(entry.prompt.content)} -- Pending`}
                                                tabIndex={0}
                                                className={'bg-platinum-600 p-1 m-1 text-xs rounded shadow text-columbia_blue-900'}
                                                dangerouslySetInnerHTML={renderHtml(entry.prompt.content)}/>
                                            : <div
                                                aria-label={`${stripHtml(entry.prompt.content)} -- Incorrect`}
                                                tabIndex={0}
                                                className={'bg-burnt_sienna-400 p-1 m-1 text-xs rounded shadow text-columbia_blue-900'}
                                                dangerouslySetInnerHTML={renderHtml(entry.prompt.content)}/>)}
                                </div>
                            </div>)}
                    </div>
                    <div
                        className={"relative bg-columbia_blue-400 text-sm rounded text-columbia_blue-800 mb-2 cursor-black h-full overflow-y-auto"}
                        tabIndex={0}
                        aria-label={'Understand Elements'}>
                        <p className={'font-bold text-lg'}>Understand</p>
                        {standardElementsModal.standardData.filter(item => knowShowElements.find(entry => entry._id.$oid === item.knowShowId).type === 'Know').map(item =>
                            (item.result === true || item.result === 0) ?
                                <button className={'cursor-pointer bg-columbia_blue-600 hover:bg-columbia_blue-700 p-2 m-2 rounded shadow text-columbia_blue-900'} onClick={() => setQuestionPreviewModal(item.questionTemplateId)}
                                   aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Correct`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</button>
                                : (item.result === 'pending') ?
                                    <p className={'bg-platinum-600 p-2 m-2 rounded shadow text-columbia_blue-900'}
                                       tabIndex={0}
                                       aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Pending`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</p>
                                    :
                                    <button className={'bg-burnt_sienna-400 p-2 m-2 rounded shadow text-columbia_blue-900'}
                                            onClick={() => setQuestionPreviewModal(item.questionTemplateId)}
                                       aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Incorrect`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</button>)}
                    </div>
                    <div
                        className={"relative bg-columbia_blue-400 text-sm rounded text-columbia_blue-800 mb-2 cursor-black h-full overflow-y-auto"}
                        tabIndex={0}
                        aria-label={'Apply Elements'}>
                        <p className={'font-bold text-lg'}>Apply</p>
                        {standardElementsModal.standardData.filter(item => knowShowElements.find(entry => entry._id.$oid === item.knowShowId).type === 'Show').map(item =>
                            (item.result === true || item.result === 0) ?
                                <button className={'bg-columbia_blue-600 hover:bg-columbia_blue-700 cursor-pointer p-2 m-2 rounded shadow text-columbia_blue-900'}
                                        onClick={() => setQuestionPreviewModal(item.questionTemplateId)}
                                   aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Correct`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</button>
                                : (item.result === 'pending') ?
                                    <p className={'bg-platinum-600 p-2 m-2 rounded shadow text-columbia_blue-900'}
                                       tabIndex={0}
                                       aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Pending`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</p>
                                    :
                                    <button className={'bg-burnt_sienna-400 hover:bg-burnt_sienna-300 cursor-pointer p-2 m-2 rounded shadow text-columbia_blue-900'}
                                            onClick={() => setQuestionPreviewModal(item.questionTemplateId)}
                                       aria-label={`Element: ${knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}. Most recent performance: Incorrect`}>{knowShowElements.find(entry => entry._id.$oid === item.knowShowId).text}</button>)}
                    </div>
                </div>

            </div>
            {(questionPreviewModal) && <QuestionPreviewModal user={user} questionPreviewModal={questionPreviewModal} setQuestionPreviewModal={setQuestionPreviewModal}/>}
        </div>

    );
}

export default StandardElementsModal;