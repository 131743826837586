import React, {useEffect, useRef, useState} from 'react';
import KnowShowStackedBarChart from "./knowShowStackedBarChart";
import AnswerDistributionPieChart from "./answerDistributionPieChart";
import TraditionalMCPreview from "./traditionalMCPreview";
import ImageMcPreview from "./imageMCPreview";
import HistoryBarChart from "./historyBarChart";
import TraditionalMCScrambledPreview from "./traditionalMCScrambledPreview";
import DOMPurify from 'dompurify';
import ImageMCPreview from "./imageMCPreview";
import ImageMCScrambledPreview from "./imageMCScrambledPreview";
import MatchingScrambledPreview from "./matchingScrambledPreview";
import MatchingPreview from "./matchingPreview";
import RecallBarChart from "./recallBarChart";
import MatchingPromptPieChart from "./matchingPromptPieChart";
import TraditionalMCAnswerDistribution from "./traditionalMCAnswerDistribution";
import ImageMCAnswerDistribution from "./imageMCAnswerDistribution";
import MatchingAnswerDistribution from "./matchingAnswerDistribution";
import RecallSummaryAnswerDistribution from "./recallSummaryAnswerDistribution";
import SortingScrambledPreview from "./sortingScrambledPreview";
import SortingAnswerDistribution from "./sortingAnswerDistribution";
import ViewAssessmentItemsModal from "../planning/viewAssessmentItemsModal";
import ReteachModal from "./reteachModal";


function StandardDataModal({user, rosters, knowShowCharts, assessmentItems, studentData, standardDataModal, setStandardDataModal, studentIds, setStudentData}) {
    const [selectedRoster, setSelectedRoster] = useState(rosters.filter(roster => new Date(roster.startDate.$date) <= new Date() && new Date(roster.endDate.$date) >= new Date()).filter(roster => roster.prep === standardDataModal.standardSet.prepTitle).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))[0]);
    const [selectedKnowShowId, setSelectedKnowShowId] = useState(null);
    const [selectedStandardElement, setSelectedStandardElement] = useState(null);
    const [reteachModal, setReteachModal] = useState(null);
    const [pieChart, setPieChart] = useState(null);
    const [questionTemplate, setQuestionTemplate] = useState(null);
    const [historyBarChartData, setHistoryBarChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [projectorView, setProjectorView] = useState(true);
    const [recallDataStyle, setRecallDataStyle] = useState('summary');

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const handleProjectorToggle = () => {
        setProjectorView(!projectorView);
    }

    const handleAssignClick = (assignment) => {
        if (window.confirm('Send currently selected question to current roster? Assignments will be immediately available and cannot be taken back from the database.')) {
            let body = {
                question: assignment,
                students: selectedRoster.students,
                prep: selectedRoster.prep
            }
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/planning/postAssignment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)})
                .then((response) => {
                    if (response.ok) {
                        window.alert('Assignment successfully posted!')
                        setIsLoading(false)
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
        } else {
            return null;
        }
    }

    const recallBarChartData = knowShowCharts.filter(entry => entry.standard === standardDataModal.standardCode).filter(entry => entry.type === "Recall").sort((a, b) => a.title.localeCompare(b.title)).map(element => ({
        name: element.title,
        elementId: element._id.$oid,
        Correct: studentData.filter(student => (selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(item => item.knowShowId === element._id.$oid).flatMap(item => item.recallItems).filter(item => item.active.correct === true).length,
        Incorrect: studentData.filter(student => (selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(item => item.knowShowId === element._id.$oid).flatMap(item => item.recallItems).filter(item => item.active.correct === false).length,
        Pending: studentData.filter(student => (selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(item => item.knowShowId === element._id.$oid).flatMap(item => item.recallItems).filter(item => item.active.correct === 'pending').length
    })).filter(item => item.Correct + item.Incorrect + item.Pending > 0)

    const understandBarChartData = knowShowCharts.filter(entry => entry.standard === standardDataModal.standardCode && entry.type === 'Know').filter(entry => entry.type !== "Vocab" && entry.type !== 'Recall').sort((a, b) => a.text.localeCompare(b.text)).map(knowShowElement => ({
        name: knowShowElement.text,
        elementId: knowShowElement._id.$oid,
        Correct: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => item.result !== 'pending' && (typeof item.result === 'number' && item.result === 0 || typeof item.result === 'boolean' && item.result)).filter(item => item.knowShowId === knowShowElement._id.$oid).length,
        Incorrect: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => (typeof item.result === 'number' && item.result > 0 || typeof item.result === 'boolean' && !item.result)).filter(item => item.knowShowId === knowShowElement._id.$oid).length,
        Pending: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => item.result === 'pending').filter(item => item.knowShowId === knowShowElement._id.$oid).length
    })).filter(item => item.Correct + item.Incorrect + item.Pending > 0)

    const applyBarChartData = knowShowCharts.filter(entry => entry.standard === standardDataModal.standardCode && entry.type === 'Show').filter(entry => entry.type !== "Vocab" && entry.type !== 'Recall').sort((a, b) => a.text.localeCompare(b.text)).map(knowShowElement => ({
        name: knowShowElement.text,
        elementId: knowShowElement._id.$oid,
        Correct: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => item.result !== 'pending' && (typeof item.result === 'number' && item.result === 0 || typeof item.result === 'boolean' && item.result)).filter(item => item.knowShowId === knowShowElement._id.$oid).length,
        Incorrect: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => (typeof item.result === 'number' && item.result > 0 || typeof item.result === 'boolean' && !item.result)).filter(item => item.knowShowId === knowShowElement._id.$oid).length,
        Pending: studentData.filter(student => (student.preps.includes(standardDataModal.standardSet.prepTitle) && selectedRoster.students.includes(student.studentId))).flatMap(item => item.active[standardDataModal.standardSet.prepTitle]).filter(entry => !Object.keys(entry).includes('dateAssigned') || (new Date(entry.dateAssigned.$date) >= new Date(selectedRoster.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(selectedRoster.endDate.$date))).filter(item => item.result === 'pending').filter(item => item.knowShowId === knowShowElement._id.$oid).length
    })).filter(item => item.Correct + item.Incorrect + item.Pending > 0)

    const onClose = () => {
        setStandardDataModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleRosterClick = (e) => {
        if (!selectedKnowShowId) {
            setSelectedRoster(rosters.find(roster => roster._id.$oid === e.target.id))
        } else {
            setSelectedRoster(rosters.find(roster => roster._id.$oid === e.target.id))
            try {
                let relevantData = studentData.filter(entry => rosters.find(roster => roster._id.$oid === e.target.id).students.includes(entry.studentId)).flatMap(item => item.active[standardDataModal.standardSet.prepTitle])
                let template = assessmentItems.find(item => relevantData.filter(data => (data.knowShowId === selectedKnowShowId)).filter(data => data.result !== "pending")[0].questionTemplateId === item._id.$oid);
                setQuestionTemplate(template);

                let possibleResponses = [template.correct, template.distractor1, template.distractor2, template.distractor3, 'pending'];

                let pieChartData = possibleResponses.map(item =>
                    (item === template.correct)
                        ? {
                            name: item,
                            value: relevantData.filter(entry => (entry.knowShowId === selectedKnowShowId && entry.response === item)).length,
                            fill: "#538ea5"
                        }
                        : (item !== 'pending') ? {
                                name: item,
                                value: relevantData.filter(entry => (entry.knowShowId === selectedKnowShowId && entry.response === item)).length,
                                fill: "#dd6e42"
                            }
                            : {
                                name: item,
                                value: relevantData.filter(entry => (entry.knowShowId === selectedKnowShowId && entry.response === item)).length,
                                fill: "#8b8b8b"
                            });
                setPieChart(pieChartData);

                let relevantHistoryData = [...relevantData, ...studentData.filter(entry => rosters.find(roster => roster._id.$oid === e.target.id).students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned'));
                let uniqueDates = new Set();
                let historyDataDates = relevantHistoryData.filter(item => {
                    if (Object.keys(item).includes('dateAssigned') && item.knowShowId === selectedKnowShowId ) {
                        const date = (item.dateAssigned.$date) ? new Date(item.dateAssigned.$date) : new Date(item.dateAssigned);
                        uniqueDates.add(date.toISOString());
                        return true;
                    }
                    return false;
                });
                let historyBarChartDataConstructor = [...uniqueDates].map(item => ({
                    name: item,
                    Correct: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result === 0 || typeof entry.result === 'boolean' && entry.result)).length,
                    Incorrect: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result > 0 || typeof entry.result === 'boolean' && !entry.result)).length,
                    Pending: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => entry.result === 'pending').length,
                    question: assessmentItems.find(question => relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item))[0].questionTemplateId === question._id.$oid),
                }));
                historyBarChartDataConstructor.sort((a, b) => a.name.localeCompare(b.name));
                setHistoryBarChartData(historyBarChartDataConstructor);

            } catch (error) {
                console.log(error)
                setQuestionTemplate(null);
                setProjectorView(true);
                setPieChart(null);
            }
        }
    }

    const handleBarClick = (e) => {
        if (e !== null) {
            try {
                let relevantData = studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.active[standardDataModal.standardSet.prepTitle])
                setSelectedKnowShowId(e.activePayload[0].payload.knowShowId)
                let pieChartKSId = e.activePayload[0].payload.knowShowId
                let template = assessmentItems.find(item => relevantData.filter(data => (data.knowShowId === e.activePayload[0].payload.knowShowId)).filter(data => data.result !== "pending")[0].questionTemplateId === item._id.$oid);
                setQuestionTemplate(template);
                let possibleResponses = [template.correct, template.distractor1, template.distractor2, template.distractor3, 'pending'];
                if (template.type === 'Traditional MC' || template.type === 'Image MC') {
                    let pieChartData = possibleResponses.map(item =>
                        (item === template.correct)
                            ? {name: item, value: relevantData.filter(entry => (entry.knowShowId === pieChartKSId && entry.response === item)).length, fill: "#538ea5"}
                            : (item !== 'pending') ? {name: item, value: relevantData.filter(entry => (entry.knowShowId === pieChartKSId && entry.response === item)).length, fill: "#dd6e42"}
                                : {name: item, value: relevantData.filter(entry => (entry.knowShowId === pieChartKSId && entry.response === item)).length, fill: "#8b8b8b"});
                    setPieChart(pieChartData);
                }


                let relevantHistoryData = [...relevantData, ...studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned'));
                let uniqueDates = new Set();
                let historyDataDates = relevantHistoryData.filter(item => {
                    if (Object.keys(item).includes('dateAssigned') && item.knowShowId === e.activePayload[0].payload.knowShowId ) {
                        const date = (item.dateAssigned.$date) ? new Date(item.dateAssigned.$date) : new Date(item.dateAssigned);
                        uniqueDates.add(date.toISOString());
                        return true;
                    }
                    return false;
                });
                let historyBarChartDataConstructor = [...uniqueDates].map(item => ({
                    name: item,
                    Correct: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result === 0 || typeof entry.result === 'boolean' && entry.result)).length,
                    Incorrect: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result > 0 || typeof entry.result === 'boolean' && !entry.result)).length,
                    Pending: relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => entry.result === 'pending').length,
                    question: assessmentItems.find(question => relevantHistoryData.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => entry.result === true || entry.result === false)[0].questionTemplateId === question._id.$oid),
                }));
                historyBarChartDataConstructor.sort((a, b) => a.name.localeCompare(b.name));
                setHistoryBarChartData(historyBarChartDataConstructor);
            } catch {
                return null;
            }
        }
    }

    const modalRef = useRef(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setStandardDataModal, setSelectedStandardElement, setSelectedRoster]);

    const handleStandardElementClick = (e) => {
        if (Object.keys(e).includes('elementId')) {
            try {
                setSelectedStandardElement(knowShowCharts.find(item => item._id.$oid === e.elementId))
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                setSelectedStandardElement(knowShowCharts.find(item => item._id.$oid === e.activePayload[0].payload.elementId))
            } catch (error) {
                console.log(error)
            }
        }
    }



    let question = null;


    if (selectedRoster && selectedStandardElement) {
        try {
            if (assessmentItems.find(assessmentItem => assessmentItem._id.$oid === studentData
                .filter(entry => selectedRoster.students.includes(entry.studentId))
                .flatMap(item => item.active[standardDataModal.standardSet.prepTitle])
                .filter(item => item.knowShowId === selectedStandardElement._id.$oid).filter(item => item.type !== 'Recall')[0].questionTemplateId)) {
                question = assessmentItems.find(assessmentItem => assessmentItem._id.$oid === studentData
                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                    .flatMap(item => item.active[standardDataModal.standardSet.prepTitle])
                    .filter(item => item.knowShowId === selectedStandardElement._id.$oid).filter(item => item.type !== 'Recall')[0].questionTemplateId)
            } else {
                question = assessmentItems.find(assessmentItem => assessmentItem._id.$oid === studentData
                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                    .flatMap(item => item.active[standardDataModal.standardSet.prepTitle])
                    .filter(item => item.knowShowId === selectedStandardElement._id.$oid).filter(item => item.type !== 'Recall')[0].questionTemplateId.$oid)
            }

        } catch (error) {
            question = null
        }
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10 h-screen"} id="wrapper" onClick={handleClose}>
            <div className={"relative bg-dutch_white-600 p-2 rounded w-[90%] max-h-[90%]"} ref={modalRef}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={"bg-columbia_blue-800 p-4 text-black cursor-black rounded h-[100%] overflow-y-scroll"}>
                    <p className={'font-bold text-dutch_white-300 mb-4 text-2xl'}>{standardDataModal.standard.code}: {standardDataModal.standard.title}</p>
                    <div className={'flex gap-4'}>
                        {rosters.filter(roster => new Date(roster.startDate.$date) <= new Date() && new Date(roster.endDate.$date) >= new Date()).filter(roster => roster.prep === standardDataModal.standardSet.prepTitle).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map(roster => (roster === selectedRoster) ?
                            <div id={roster._id.$oid} className={'py-2 px-4 rounded-t bg-dutch_white-200'}>
                                <p id={roster._id.$oid} className={'font-bold text-dutch_white-800 select-none'}>{roster.title}</p>
                            </div>
                            :
                            <div id={roster._id.$oid} className={'py-2 px-4 rounded-t bg-columbia_blue-700 hover:bg-columbia_blue-600 cursor-pointer unselected-tab'} onClick={handleRosterClick}>
                                <p id={roster._id.$oid} className={'font-bold text-dutch_white-400 select-none'}>{roster.title}</p>
                            </div>)}
                    </div>
                    <div className={'p-1 pt-2 bg-dutch_white-200 flex flex-col rounded-b rounded-tr'}>
                        <div className={'grid grid-cols-3 border-b-2 border-burnt_sienna'}>
                            <div className={'flex flex-col'}>
                                <p className={'font-bold text-3xl text-paynes_gray-500 underline'}>Recall</p>
                                {(recallBarChartData.length > 0) ? <div className={''}>
                                        <RecallBarChart className={'mx-auto'} data={recallBarChartData} handleBarClick={handleStandardElementClick}/>
                                    </div>:
                                    <p className={'my-auto font-bold text-paynes_gray-500'}>No data yet</p>}
                            </div>
                            <div className={'flex flex-col'}>
                                <p className={'font-bold text-3xl text-paynes_gray-500 underline'}>Understand</p>
                                {(understandBarChartData.length > 0 ?
                                        <div className={''}>
                                            <KnowShowStackedBarChart className={'mx-auto'} data={understandBarChartData}
                                                                     handleBarClick={handleStandardElementClick}/>
                                        </div>: <p className={'my-auto font-bold text-paynes_gray-500'}>No data yet</p>
                                )}
                            </div>
                            <div className={'flex flex-col'}>
                                <p className={'font-bold text-3xl text-paynes_gray-500 underline'}>Apply</p>
                                {(applyBarChartData.length > 0 ?
                                        <div className={''}>
                                            <KnowShowStackedBarChart className={'mx-auto'} data={applyBarChartData}
                                                                     handleBarClick={handleStandardElementClick}/>
                                        </div>:
                                        <p className={'my-auto font-bold text-paynes_gray-500'}>No data yet</p>
                                )}
                            </div>
                            {/*<div className={'w-[250px] flex flex-col h-[275px]'}>*/}
                            {/*    {(selectedKnowShowId) &&*/}
                            {/*        <p className={'text-dutch_white-800 mt-4'}>{knowShowCharts.find(entry => entry._id.$oid === selectedKnowShowId).text}</p>}*/}
                            {/*    {(questionTemplate && questionTemplate.type !== 'Matching' && pieChart) ?*/}
                            {/*        <AnswerDistributionPieChart data={pieChart}/> :*/}
                            {/*        <p className={'text-dutch_white-800 mt-4'}>Click a bar to evaluate data</p>}*/}
                            {/*</div>*/}
                            <div className={'grow'}/>
                            {(questionTemplate) ? (projectorView) ?
                                    <div onClick={handleProjectorToggle}
                                         className={'h-fit p-4 mt-auto rounded bg-columbia_blue hover:bg-columbia_blue-600 select-none'}>Projector
                                        View</div>
                                    : <div onClick={handleProjectorToggle}
                                           className={'h-fit p-4 mt-auto rounded bg-burnt_sienna hover:bg-burnt_sienna-600 select-none text-dutch_white-400'}>Projector
                                        View</div>
                                : <></>}

                        </div>
                        {(selectedStandardElement) && (selectedStandardElement.type === 'Recall') ?
                            <div className={'flex flex-col w-full'}>
                                <p className={'mx-auto mt-4 font-bold text-4xl text-paynes_gray-500'}>{selectedStandardElement.title}</p>
                                <div className={'p-2 flex flex-col max-h-full'}>
                                    <div className={'flex gap-4'}>
                                        {(recallDataStyle === "summary") ?
                                            <div className={'py-2 px-4 rounded-t bg-columbia_blue-800 select-none'} >
                                                <p className={'text-dutch_white-300'}>Summary Data</p>
                                            </div>
                                            :
                                            <div className={'py-2 px-4 rounded-t bg-columbia_blue-800 unselected-tab select-none cursor-pointer'} onClick={() => setRecallDataStyle('summary')}>
                                                <p className={'text-dutch_white-300'}>Summary Data</p>
                                            </div>}
                                        {/*{(recallDataStyle === "detailed") ?*/}
                                        {/*    <div className={'py-2 px-4 rounded-t bg-paynes_gray-400 select-none'}>*/}
                                        {/*        <p className={'text-dutch_white-300'}>Detailed Data</p>*/}
                                        {/*    </div>*/}
                                        {/*    :*/}
                                        {/*    <div className={'py-2 px-4 rounded-t bg-paynes_gray-400 select-none unselected-tab cursor-pointer'} onClick={() => setRecallDataStyle('detailed')}>*/}
                                        {/*        <p className={'text-dutch_white-300'}>Detailed Data</p>*/}
                                        {/*    </div>}*/}
                                    </div>
                                    {(recallDataStyle === 'summary') ?
                                        <div>
                                            <RecallSummaryAnswerDistribution
                                                recallItems={assessmentItems
                                                    .find(item => item.knowShowId === selectedStandardElement._id.$oid).recallItems}
                                                studentData={studentData
                                                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                    .map(entry => ({
                                                        data: entry.active[standardDataModal.standardSet.prepTitle].find(item => item.knowShowId === selectedStandardElement._id.$oid),
                                                        firstName: entry.firstName,
                                                        lastName: entry.lastName
                                                    }))
                                                } />
                                        </div>

                                        :
                                        // <div className={'grid sm:grid-cols-2 md:grid-cols-4 gap-4 bg-paynes_gray-400 p-2 rounded-b rounded-tr'}>
                                        //     {assessmentItems.find(item => item.knowShowId === selectedStandardElement._id.$oid).recallItems.map((item, index) => (
                                        //         <div
                                        //             className={'p-2 rounded bg-dutch_white-100 shadow border-2 border-columbia_blue-800 flex flex-col'}>
                                        //             <div className={'font-bold text-columbia_blue-800 text-xl'}
                                        //                  dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>
                                        //             <MatchingPromptPieChart
                                        //                 scoringData={[
                                        //                     {
                                        //                         name: 'Correct',
                                        //                         value: studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content && entry.active.correct === true).length,
                                        //                         fill: '#538EA5FF',
                                        //                         total: studentData.filter(entry => entry.data).filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content).length,
                                        //                         prompt: item.prompt.content
                                        //                     },
                                        //
                                        //                     {
                                        //                         name: 'Incorrect',
                                        //                         value: studentData.filter(entry => entry.data).filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content && entry.active.correct === false).length,
                                        //                         fill: '#DD6E42FF',
                                        //                         total: studentData.filter(entry => entry.data).filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content).length,
                                        //                         prompt: item.prompt.content
                                        //
                                        //                     },
                                        //                     {
                                        //                         name: 'Pending',
                                        //                         value: studentData.filter(entry => entry.data).filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content && entry.active.correct === 'pending').length,
                                        //                         fill: '#8B8B8BFF',
                                        //                         total: studentData.filter(entry => entry.data).filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content).length,
                                        //                         prompt: item.prompt.content
                                        //
                                        //                     },
                                        //                 ]}
                                        //                 incorrectDistribution={studentData.filter(entry => entry.data)
                                        //                     .filter(entry => selectedRoster.students.includes(entry.studentId))
                                        //                     .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                        //                     .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid)
                                        //                     .flatMap(entry => entry.recallItems)
                                        //                     .filter(entry => entry.prompt.content === item.prompt.content && entry.active.correct === false)
                                        //                     .reduce((acc, entry) => {
                                        //                         const seenResponses = acc.seenResponses;
                                        //                         if (!seenResponses.has(entry.active.response)) {
                                        //                             seenResponses.add(entry.active.response);
                                        //                             acc.filteredEntries.push(entry);
                                        //                         }
                                        //                         return acc;
                                        //                     }, {seenResponses: new Set(), filteredEntries: []})
                                        //                     .filteredEntries
                                        //                     .map(response => (
                                        //                         {
                                        //                             name: response.active.response,
                                        //                             prompt: item.prompt.content,
                                        //                             value: studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle]).filter(entry => entry.knowShowId === selectedStandardElement._id.$oid).flatMap(entry => entry.recallItems).filter(entry => entry.prompt.content === item.prompt.content && entry.active.response === response.active.response).length,
                                        //                             fill: '#DD6E42FF'
                                        //                         })
                                        //                     )}
                                        //                 studentData={studentData.filter(entry => entry.data)
                                        //                     .filter(entry => selectedRoster.students.includes(entry.studentId))
                                        //                     .map(entry => ({
                                        //                         data: entry.active[standardDataModal.standardSet.prepTitle].find(item => item.knowShowId === selectedStandardElement._id.$oid),
                                        //                         firstName: entry.firstName,
                                        //                         lastName: entry.lastName
                                        //                     }))
                                        //                 }
                                        //             />
                                        //         </div>))}
                                        // </div>}
                                    <></>}
                                </div>

                            </div>
                            :
                            <div>
                                {question ? (
                                    question.type === 'Traditional MC' ? (
                                        <>
                                            <div className={'grid grid-cols-2 gap-2'}>
                                                <TraditionalMCScrambledPreview assessmentItem={question}/>

                                                <div
                                                    className={'rounded bg-platinum-800 text-dutch_white-400 shadow p-2 m-2 flex flex-col gap-1'}>
                                                    {(studentData
                                                            .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                            .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                            .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending').length > 0) &&
                                                        <>
                                                            <p className={'font-bold'}>Pending</p>
                                                            <div
                                                                className={'max-h-fill overflow-auto flex flex-wrap gap-1'}>
                                                                {studentData
                                                                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                    .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                    .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending')
                                                                    .map(student =>
                                                                        <div
                                                                            className={'p-1 bg-platinum-700 text-platinum-400 px-4 rounded'}>
                                                                            <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName} </p>
                                                                        </div>)}
                                                            </div>
                                                        </>}
                                                    <div className={'w-full'}>
                                                        <p className={'text-burnt_sienna font-bold text-2xl mt-2'}>History</p>
                                                        <HistoryBarChart assessmentItems={assessmentItems}
                                                                         data={[...studentData
                                                                             .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                             .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                             .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid), ...studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned') && item.knowShowId === selectedStandardElement._id.$oid)}/>
                                                    </div>

                                                </div>
                                            </div>
                                            <TraditionalMCAnswerDistribution question={question} responses={studentData
                                                .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle].filter(questionResponse => questionResponse.knowShowId === selectedStandardElement._id.$oid))}
                                                                             studentData={studentData}/>
                                        </>
                                    ) : question.type === 'Image MC' ? (
                                        <>
                                            <div className={'flex'}>
                                                <ImageMCScrambledPreview assessmentItem={question}/>

                                                <div
                                                    className={'rounded bg-platinum-800 text-dutch_white-400 w-[50%] h-full shadow p-2 m-2 flex flex-col gap-1'}>
                                                    {(studentData
                                                            .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                            .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                            .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending').length > 0) &&
                                                        <>
                                                            <p className={'font-bold'}>Pending</p>
                                                            <div
                                                                className={'max-h-fill overflow-auto flex flex-wrap gap-1'}>
                                                                {studentData
                                                                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                    .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                    .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending')
                                                                    .map(student =>
                                                                        <div
                                                                            className={'p-1 bg-platinum-700 text-platinum-400 px-4 rounded'}>
                                                                            <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName} </p>
                                                                        </div>)}
                                                            </div></>}
                                                    <div className={'w-full'}>
                                                        <p className={'text-burnt_sienna font-bold text-2xl mt-2'}>History</p>
                                                        <HistoryBarChart assessmentItems={assessmentItems}
                                                                         data={[...studentData
                                                                             .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                             .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                             .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid), ...studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned') && item.knowShowId === selectedStandardElement._id.$oid)}/>
                                                    </div>

                                                </div>
                                            </div>
                                            <ImageMCAnswerDistribution question={question} responses={studentData
                                                .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle].filter(questionResponse => questionResponse.knowShowId === selectedStandardElement._id.$oid))}
                                                                       studentData={studentData}/>
                                        </>
                                    ) : question.type === 'Matching' ? (
                                        <>
                                            <div className={'flex'}>
                                                <MatchingScrambledPreview assessmentItem={question}/>
                                                <div
                                                    className={'rounded bg-platinum-800 text-dutch_white-400 w-[50%] h-full shadow p-2 m-2 flex flex-col gap-1'}>
                                                    {(studentData
                                                            .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                            .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                            .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending').length > 0) &&
                                                        <>
                                                            <p className={'font-bold'}>Pending</p>
                                                            <div
                                                                className={'max-h-fill overflow-auto flex flex-wrap gap-1'}>
                                                                {studentData
                                                                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                    .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                    .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending')
                                                                    .map(student =>
                                                                        <div
                                                                            className={'p-1 bg-platinum-700 text-platinum-400 px-4 rounded'}>
                                                                            <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName} </p>
                                                                        </div>)}
                                                            </div>
                                                        </>}
                                                    <div className={'w-full'}>
                                                        <p className={'text-burnt_sienna font-bold text-2xl mt-2'}>History</p>
                                                        <HistoryBarChart assessmentItems={assessmentItems}
                                                                         data={[...studentData
                                                                             .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                             .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                             .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid), ...studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned') && item.knowShowId === selectedStandardElement._id.$oid)}/>
                                                    </div>

                                                </div>

                                            </div>
                                            <MatchingAnswerDistribution question={question} responses={studentData
                                                .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle].filter(questionResponse => questionResponse.knowShowId === selectedStandardElement._id.$oid))}
                                                                        studentData={studentData}/>
                                        </>
                                    ) : question.type === 'Sorting' ? (
                                        <>
                                            <div className={'flex'}>
                                                <SortingScrambledPreview assessmentItem={question}/>
                                                <div
                                                    className={'rounded bg-platinum-800 text-dutch_white-400 w-[50%] h-full shadow p-2 m-2 flex flex-col gap-1'}>
                                                    {(studentData
                                                            .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                            .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                            .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending').length > 0) &&
                                                        <>
                                                            <p className={'font-bold'}>Pending</p>
                                                            <div
                                                                className={'max-h-fill overflow-auto flex flex-wrap gap-1'}>
                                                                {studentData
                                                                    .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                    .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                    .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid && entry.result === 'pending')
                                                                    .map(student =>
                                                                        <div
                                                                            className={'p-1 bg-platinum-700 text-platinum-400 px-4 rounded'}>
                                                                            <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName} </p>
                                                                        </div>)}
                                                            </div>
                                                        </>}
                                                    <div className={'w-full'}>
                                                        <p className={'text-burnt_sienna font-bold text-2xl mt-2'}>History</p>
                                                        <HistoryBarChart assessmentItems={assessmentItems}
                                                                         data={[...studentData
                                                                             .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                                             .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle])
                                                                             .filter(entry => entry.knowShowId === selectedStandardElement._id.$oid), ...studentData.filter(entry => selectedRoster.students.includes(entry.studentId)).flatMap(item => item.archive[standardDataModal.standardSet.prepTitle])].filter(item => Object.keys(item).includes('dateAssigned') && item.knowShowId === selectedStandardElement._id.$oid)}/>
                                                    </div>

                                                </div>

                                            </div>
                                            <SortingAnswerDistribution question={question} responses={studentData
                                                .filter(entry => selectedRoster.students.includes(entry.studentId))
                                                .flatMap(entry => entry.active[standardDataModal.standardSet.prepTitle].filter(questionResponse => questionResponse.knowShowId === selectedStandardElement._id.$oid))}
                                                                        studentData={studentData}/>
                                        </>
                                    ) : (
                                        <p>No matching question type found</p>
                                    )
                                ) : (
                                    <p>No standard element selected</p>
                                )}
                                {(selectedStandardElement) && <button onClick={() => setReteachModal({standard: selectedStandardElement, roster: selectedRoster, assessmentItems: assessmentItems.filter(item => item.knowShowId === selectedStandardElement._id.$oid)})} className={'rounded px-6 py-2 font-bold text-dutch_white-300 mt-4 bg-columbia_blue-700 hover:bg-columbia_blue-800 cursor-pointer w-fit mx-auto'}>Assess Reteach</button>}
                            </div>
                        }
                        {/*{(questionTemplate) &&*/}
                        {/*    <div className={'flex'}>*/}
                        {/*        <div className={'grow'}/>*/}
                        {/*        {(questionTemplate.type === 'Traditional MC') ?*/}
                        {/*            (projectorView) ? <TraditionalMCScrambledPreview assessmentItem={questionTemplate} /> : <TraditionalMCPreview assessmentItem={questionTemplate} />*/}
                        {/*        : (questionTemplate.type === 'Image MC') ?*/}
                        {/*                (projectorView) ? <ImageMCScrambledPreview assessmentItem={questionTemplate} /> : <ImageMCPreview assessmentItem={questionTemplate} />*/}
                        {/*        : (questionTemplate.type === 'Matching') ?*/}
                        {/*                (projectorView) ? <MatchingScrambledPreview assessmentItem={questionTemplate} /> : <MatchingPreview assessmentItem={questionTemplate} />*/}
                        {/*                    : <></>}*/}
                        {/*        <div className={'grow'}/>*/}
                        {/*        {(historyBarChartData) && (historyBarChartData.length > 1) && <div className={'flex flex-col'}>*/}
                        {/*            <p className={'font-bold text-2xl'}>Performance History</p>*/}
                        {/*            <HistoryBarChart data={historyBarChartData} handleBarClick={() => console.log('click')}/>*/}
                        {/*        </div>}*/}
                        {/*        <div className={'grow'}/>*/}
                        {/*    </div>}*/}
                        {/*{(!projectorView) && <>*/}

                        {/*    {(questionTemplate) && (questionTemplate.type === "Traditional MC") ?*/}
                        {/*        <div className={'grid grid-cols-5 gap-2 px-2'}>*/}
                        {/*            <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                <p className={'font-bold '}>Correct</p>*/}
                        {/*                <div dangerouslySetInnerHTML={renderHtml(questionTemplate.correct)} />*/}
                        {/*                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                    {selectedRoster.students*/}
                        {/*                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                        .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.correct)*/}
                        {/*                            .map( item => (*/}
                        {/*                                <div className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                </div>)) )}*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                <p className={'font-bold '}>Distractor 1</p>*/}
                        {/*                <div dangerouslySetInnerHTML={renderHtml(questionTemplate.distractor1)} />*/}
                        {/*                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                    {selectedRoster.students*/}
                        {/*                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                        .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor1)*/}
                        {/*                            .map( item => (*/}
                        {/*                                <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                </div>)) )}*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                <p className={'font-bold '}>Distractor 2</p>*/}
                        {/*                <div dangerouslySetInnerHTML={renderHtml(questionTemplate.distractor2)} />*/}
                        {/*                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                    {selectedRoster.students*/}
                        {/*                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                        .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor2)*/}
                        {/*                            .map( item => (*/}
                        {/*                                <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                </div>)) )}*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                <p className={'font-bold '}>Distractor 3</p>*/}
                        {/*                <div dangerouslySetInnerHTML={renderHtml(questionTemplate.distractor3)} />*/}
                        {/*                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                    {selectedRoster.students*/}
                        {/*                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                        .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor3)*/}
                        {/*                            .map( item => (*/}
                        {/*                                <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                </div>)) )}*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'rounded bg-platinum-800 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                <p className={'font-bold '}>Pending</p>*/}
                        {/*                <p className={'mb-2'}>Awaiting Responses</p>*/}
                        {/*                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                    {selectedRoster.students*/}
                        {/*                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                        .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === 'pending')*/}
                        {/*                            .map( item => (*/}
                        {/*                                <div className={'p-1 bg-platinum-700 text-sm text-platinum-400 rounded'}>*/}
                        {/*                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                </div>)) )}*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div> : (questionTemplate.type === "Image MC") ?*/}
                        {/*            <div className={'grid grid-cols-5 gap-2 px-2'}>*/}
                        {/*                <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                    <p className={'font-bold'}>Correct</p>*/}
                        {/*                    <img className={'max-w-[150px] mx-auto mb-2'} src={questionTemplate.correct} />*/}
                        {/*                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                        {selectedRoster.students*/}
                        {/*                            .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                            .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.correct)*/}
                        {/*                                .map( item => (*/}
                        {/*                                    <div className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                        <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                    </div>)) )}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                    <p className={'font-bold'}>Distractor 1</p>*/}
                        {/*                    <img className={'max-w-[150px] mx-auto mb-2'} src={questionTemplate.distractor1} />*/}
                        {/*                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                        {selectedRoster.students*/}
                        {/*                            .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                            .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor1)*/}
                        {/*                                .map( item => (*/}
                        {/*                                    <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                        <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                    </div>)) )}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                    <p className={'font-bold'}>Distractor 2</p>*/}
                        {/*                    <img className={'max-w-[150px] mx-auto mb-2'} src={questionTemplate.distractor2} />*/}
                        {/*                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                        {selectedRoster.students*/}
                        {/*                            .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                            .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor2)*/}
                        {/*                                .map( item => (*/}
                        {/*                                    <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                        <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                    </div>)) )}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                    <p className={'font-bold '}>Distractor 3</p>*/}
                        {/*                    <img className={'max-w-[150px] mx-auto mb-2'} src={questionTemplate.distractor3} />*/}
                        {/*                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                        {selectedRoster.students*/}
                        {/*                            .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                            .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === questionTemplate.distractor3)*/}
                        {/*                                .map( item => (*/}
                        {/*                                    <div className={'p-1 bg-burnt_sienna-200 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                        <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                    </div>)) )}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className={'rounded bg-platinum-800 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                    <p className={'font-bold '}>Pending</p>*/}
                        {/*                    <p className={'mb-2'}>Awaiting Responses</p>*/}
                        {/*                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                        {selectedRoster.students*/}
                        {/*                            .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                            .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === 'pending')*/}
                        {/*                                .map( item => (*/}
                        {/*                                    <div className={'p-1 bg-platinum-700 text-sm text-platinum-400 rounded'}>*/}
                        {/*                                        <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                    </div>)) )}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>: (questionTemplate.type === "Matching") ?*/}
                        {/*                <div className={'grid grid-cols-6 gap-2 px-2'}>*/}
                        {/*                    <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                        <p className={'font-bold'}>Perfect Match</p>*/}
                        {/*                        <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                            {selectedRoster.students*/}
                        {/*                                .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                                .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.result === 0)*/}
                        {/*                                    .map( item => (*/}
                        {/*                                        <div className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded'}>*/}
                        {/*                                            <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                        </div>)) )}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={'rounded bg-burnt_sienna-700 col-span-2 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                        <p className={'font-bold'}>Mismatched</p>*/}
                        {/*                        <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                            {[...new Set(selectedRoster.students*/}
                        {/*                                .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                                .flatMap(entry => entry.active[selectedRoster.prep]*/}
                        {/*                                    .filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.result > 0)*/}
                        {/*                                    .map(item => item.result)*/}
                        {/*                                ))*/}
                        {/*                            ].sort((a, b) => b - a) // Sort the unique values in ascending order*/}
                        {/*                                .map(result => (*/}
                        {/*                                    <div key={result} className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded mb-2'}>*/}
                        {/*                                        <p className={'font-bold underline mb-2'}>{result} mismatches</p>*/}
                        {/*                                        {selectedRoster.students*/}
                        {/*                                        .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                                        .flatMap(entry => entry.active[selectedRoster.prep]*/}
                        {/*                                        .filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.result === result)).map(student =>*/}
                        {/*                                            <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName}</p>)}*/}
                        {/*                                    </div>*/}
                        {/*                                ))}*/}

                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={'rounded bg-burnt_sienna-700 col-span-2 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                        <p className={'font-bold'}>Prompt Mismatches:</p>*/}
                        {/*                        <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                            {questionTemplate.matchSets.map(item =>*/}
                        {/*                                <div className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>*/}
                        {/*                                <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)} /><div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>{selectedRoster.students*/}
                        {/*                                .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                                .flatMap(entry => entry.active[selectedRoster.prep]*/}
                        {/*                                    .filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response !== 'pending')*/}
                        {/*                                    .flatMap(studentData => studentData.response)*/}
                        {/*                                    .filter(entry => entry.prompt.content === item.prompt.content && entry.response.content !== item.match.content))*/}
                        {/*                                    .length}</div></div>)}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={'rounded bg-platinum-800 text-dutch_white-400 shadow p-2'}>*/}
                        {/*                        <p className={'font-bold '}>Pending</p>*/}
                        {/*                        <p className={'mb-2'}>Awaiting Responses</p>*/}
                        {/*                        <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto'}>*/}
                        {/*                            {selectedRoster.students*/}
                        {/*                                .map(student => studentData.find(entry => entry.studentId === student))*/}
                        {/*                                .flatMap(entry => entry.active[selectedRoster.prep].filter(questionResponse => questionResponse.knowShowId === selectedKnowShowId && questionResponse.response === 'pending')*/}
                        {/*                                    .map( item => (*/}
                        {/*                                        <div className={'p-1 bg-platinum-700 text-sm text-platinum-400 rounded'}>*/}
                        {/*                                            <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>*/}
                        {/*                                        </div>)) )}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}

                        {/*        : <></>}*/}
                        {/*    {(selectedKnowShowId) && (historyBarChartData) && <div className={'flex flex-col'}>*/}
                        {/*        <div className={'my-2 font-bold text-xl'}>Click a question below to assign a reteach (questions with orange borders have been assigned to this roster previously)</div>*/}
                        {/*        <div className={'grid grid-cols-4 gap-2'}>*/}
                        {/*            {assessmentItems.filter(item => item.knowShowId === selectedKnowShowId).filter(item => item.status === 'active').map(item => (historyBarChartData.flatMap(entry => entry.question).includes(item)) ? <div className={'px-1 bg-burnt_sienna-600 rounded flex flex-col'} onClick={() => handleAssignClick(item)}><div className={'grow'}/><TraditionalMCPreview assessmentItem={item} /><div className={'grow'}/></div> : <div className={'px-1 bg-columbia_blue-600 rounded flex flex-col'} onClick={() => handleAssignClick(item)}><div className={'grow'}/><TraditionalMCPreview assessmentItem={item} /><div className={'grow'}/></div>)}*/}
                        {/*        </div>*/}
                        {/*    </div>}*/}
                        {/*</>}*/}
                    </div>

                </div>
            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
            {reteachModal && <ReteachModal user={user} studentIds={studentIds} reteachModal={reteachModal} setReteachModal={setReteachModal} roster={selectedRoster} prep={selectedRoster.prep} setStudentData={setStudentData}/>}
        </div>
    );
}

export default StandardDataModal;