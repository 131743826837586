import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import getWithAuth from "../../context/getWithAuth";
import Planning from "./planning";

function DesignFetchLayer() {

    const [standardSets, setStandardSets] = useState(null);
    const [rosters, setRosters] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);

    const user = useContext(UserContext);

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getStandardSets`, user.userAttributes['custom:school'], {id: user.userContext._id.$oid})
                    .then(async r => {
                        setStandardSets(r);
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchStandardSets();
        fetchRosters();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
    }, [user.userContext])

    return (
        <Planning user={user} standardSets={(standardSets) && standardSets.filter(item => user.userContext.preps.includes(item.prepTitle))} setStandardSets={setStandardSets} rosters={rosters} setRosters={setRosters} knowShowCharts={knowShowCharts} setKnowShowCharts={setKnowShowCharts} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} />
    );
}

export default DesignFetchLayer;