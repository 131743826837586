import React, {useState} from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";

function CustomToolTip({ active, payload, label }) {
    if (active && payload && payload.length) {

        const sum = payload.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value;
        }, 0);

        return (
            <div className={'bg-columbia_blue-400 text-dutch_white-800 p-1 max-w-[210px] overflow text-xs opacity-90 rounded'}>
                <p>{label}</p>
                <br />
                {payload.map(item => (<p>{item.dataKey}: {item.value} ({Math.round(item.value / sum * 100)}%)</p>))}
            </div>
        )
    }

    return null;
}

const BarWithBorder = (props) => {
        const { fill, x, y, width, height } = props;
        return (
            <g tabIndex={0} onClick={props.onClick}>
                <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
                <rect x={x} y={y} width={width} height={2} stroke="none" fill={'white'} />
            </g>
        );
};

const CustomLabelCheck = ({ x, y, width, height, value }) => {
    return (
        <svg
            x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
            y={y + height - 28}      // Position the label just above the bottom of the bar
            width={30}
            height={30}
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="2"
        >{/* Added fill to rectangle */}
            <path d="M4 12 L8 16 L16 8" stroke="#f6f0e0" strokeWidth="1.5" fill="none"/>
            {/* Centered the checkmark */}
        </svg>
    );
};

const CustomLabelX = ({ x, y, width, height, value }) => {
    return (
        <svg
            x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
            y={y + height - 20}      // Position the label just above the bottom of the bar
            width={30}
            height={30}
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="2">
            <path d="M5 2 L13 10 M13 2 L5 10" stroke="#f6f0e0" strokeWidth="1.5" fill="none"/>
            {/* Centered the checkmark */}
        </svg>
    );
};

const CustomLabelPending = ({ x, y, width, height }) => {
    return (
        <svg
            x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
            y={y + height - 25}      // Position the label just above the bottom of the bar
            width={30}
            height={30}
            viewBox="0 0 24 24"
            fill="none"
            stroke="#f6f0e0"
            strokeWidth="1">
            <text
                x="39%"                 // Center the text horizontally
                y="70%"                 // Adjust the vertical position to fit above the bar
                textAnchor="middle"     // Center the text anchor
                fill="#f6f0e0"            // Set the fill color for the text
                fontSize="16"           // Set the font size
            >
                ?
            </text>
        </svg>
    );
};


export default function KnowShowStackedBarChart({data, handleBarClick}) {
    const [focusedBar, setFocusedBar] = useState(null);
    return (
        <div className={'w-full'} onKeyDown={(e) => {
            console.log('Key pressed:', focusedBar); // Debugging: Ensure event fires
            if (e.key === 'Enter') {
                console.log('Enter key pressed'); // Debugging: Ensure condition works
                handleBarClick(focusedBar);
                console.log(focusedBar)
            }
        }}>
            <ResponsiveContainer width='100%' height={300}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                    onClick={handleBarClick}

                    role="button"
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#eeeeee"/>
                    <XAxis dataKey='name' className={'font-bold'} tick={false}/>
                    {/*<YAxis stroke="#eeeeee" />*/}
                    <Tooltip cursor={false} content={<CustomToolTip/>}/>
                    <Legend/>
                    <Bar dataKey="Correct" stackId="a" fill={'#538ea5'} onFocus={(e) => {
                        const { payload } = e
                        setFocusedBar(payload);}} shape={<BarWithBorder onClick={handleBarClick}/>} label={<CustomLabelCheck />}/>
                    <Bar dataKey="Incorrect" stackId="a" fill="#D96B3F" onFocus={(e) => {
                        const { payload } = e
                        setFocusedBar(payload);}} shape={<BarWithBorder />} label={<CustomLabelX />}/>
                    <Bar dataKey="Pending" stackId="a" fill="#8b8b8b" onFocus={(e) => {
                        const { payload } = e
                        setFocusedBar(payload);}} label={<CustomLabelPending />}/>
                </BarChart>
            </ResponsiveContainer>
        </div>

    );
}
