import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import EditRosterModal from "./editRosterModal";
import {useNavigate} from "react-router-dom";
import NewRosterModal from "./newRosterModal";
import GroupModal from "./groupModal";
import CreateUnitModal from "./createUnitModal";
import DOMPurify from "dompurify";
import ChangeUnitModal from "./changeUnitModal";
import ViewAssessmentItemsModal from "../planning/viewAssessmentItemsModal";
import AdjustUnitModal from "./adjustUnitModal";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import ToSModal from "../../components/tosModal";
import AssignRecallModal from "../planning/assignRecallModal";
import RosterAssignmentData from "./rosterAssignmentData";
import StudentDataModal from "./studentDataModal";

function Logistics({user, rosters, setRosters, studentContext, setStudentContext, standardSets, setStandardSets, knowShowCharts, setKnowShowCharts, assessmentItems, setAssessmentItems, groupData, setGroupData, unitPlans, setUnitPlans, getUnitPlans, fetchRosters}) {


    const [editRosterModal, setEditRosterModal] = useState(null);
    const [newRosterModal, setNewRosterModal] = useState(null);
    const [groupModal, setGroupModal] = useState(null);
    const [createUnitModal, setCreateUnitModal] = useState(null);
    const [changeUnitModal, setChangeUnitModal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [viewAssessmentItemsModal, setViewAssessmentItemsModal] = useState(null);
    const [adjustUnitModal, setAdjustUnitModal] = useState(null);
    const [assignRecallModal, setAssignRecallModal] = useState(null);
    const [studentDataModal, setStudentDataModal] = useState(null);

    const today = new Date();

    const navigate = useNavigate()

    useEffect(() => {
        if (editRosterModal || newRosterModal || groupModal || createUnitModal || changeUnitModal || viewAssessmentItemsModal || adjustUnitModal || assignRecallModal || studentDataModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';}
    }, [editRosterModal, newRosterModal, groupModal, createUnitModal, changeUnitModal, viewAssessmentItemsModal, adjustUnitModal, assignRecallModal, studentDataModal])

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const handleAssignmentClick = (knowShow) => {
        if (knowShow.type === "Recall") {
            setAssignRecallModal(assessmentItems.find(item => item.knowShowId === knowShow._id.$oid))
        } else {
            setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === knowShow._id.$oid))
        }
    }

    function formatDateToMMDD(date) {
        // Extract the month and day from the date object
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();

        // Pad single digit months and days with leading zeros
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Return the formatted date as mm/dd
        return `${formattedMonth}/${formattedDay}`;
    }

    // const handleClearOutstandingAssessments = (roster) => {
    //     if (window.confirm('This will delete all outstanding assignments and keep currently pending students as pending without the option to perform the asseessment. Are you sure?')) {
    //         setIsLoading(true)
    //         fetch(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/logistics/putClearOutstandingAssessments`, {
    //             method: 'PUT',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 roster: roster,
    //             }),
    //
    //         })
    //             .then(async (response) => {
    //                 if (response.ok) {
    //                     window.alert(`Assignments cleared for ${roster.title}`)
    //                     setIsLoading(false)
    //                 }
    //             })
    //     }
    // }

    if (!user.userContext) {
        return <Loader message={'Fetching user data'}/>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (!studentContext || !assessmentItems || !rosters || !knowShowCharts || !unitPlans || !groupData) {
        const loadingItems = [
            !rosters && "Rosters",
            !studentContext && "Student Data",
            !knowShowCharts && "Standard Elements",
            !assessmentItems && "Assessment Items",
            !groupData && "Group Data",
            !unitPlans && "Unit Plans"
        ].filter(Boolean); // Filter out false/null values

        const fetchingText = loadingItems.length > 1
            ? `Fetching: ${loadingItems.slice(0, -1).join(", ")} and ${loadingItems.slice(-1)}`
            : `Fetching: ${loadingItems[0] || ""}`;
        return <Loader message={fetchingText}/>
    } else {
        return (
            <div className={'min-h-screen'}>
                {(user.userContext) && user.userContext.preps.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(prep =>
                    <div className={'bg-burnt_sienna-300 m-4 rounded shadow-lg border-[1px] border-columbia_blue-800'}>
                        <p className={"font-bold text-columbia_blue-900 text-3xl py-2"}>{prep}</p>
                        <div className={'flex'}>
                            <div className={'w-[80%] flex flex-col m-2'}>
                                <div className={'h-full w-full bg-paynes_gray-200 rounded flex flex-col border-[1px] border-columbia_blue-800 p-1'}>
                                    {(!assessmentItems) ? <div className="my-12 mx-auto animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-columbia_blue-700" /> :
                                        <div>
                                        {(user.userContext.currentUnits[prep]) && (unitPlans) && (Array.isArray(unitPlans)) && (unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep])) && <div className={'font-bold text-lg text-dutch_white-900 py-2'} dangerouslySetInnerHTML={renderHtml(unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]).title)} />}
                                        <div className={'h-[400px] overflow-y-auto py-1 rounded'}>
                                        {(assessmentItems) && (unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep])) && (unitPlans && (unitPlans.filter(item => item.prep === prep).length > 0)) ?
                                        <div className={'mx-2 bg-dutch_white-500 pt-2 rounded border-columbia_blue-800 shadow grid grid-cols-5 pb-2 px-1 gap-1'}>
                                            {(user.userContext.currentUnits[prep]) ? unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]).calendar.map((day, index) => <>
                                                        <div
                                                            className={'min-h-[98px] bg-dutch_white-300 mx-1 rounded border-4 border-dutch_white-300 flex flex-col'}>
                                                            <p className={'text-xs text-dutch_white-800 text-start ml-1 select-none'}>Day {index + 1}</p>
                                                            {day.map(element => (assessmentItems.filter(item => item.knowShowId === element._id.$oid).length > 0)
                                                                ? (element.type !== 'Recall') ?
                                                                    <div className={'mb-1'}>
                                                                        <button
                                                                            onClick={() => handleAssignmentClick(element)}
                                                                            className={'text-[0.8rem] w-full rounded-t rounded-bl p-1 select-none bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-900 shadow-md text-dutch_white-300'}>{element.text}</button>
                                                                        <div className={'flex'}>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex flex-col w-[80%]'}>
                                                                                {(rosters && Array.isArray(rosters)) && rosters.filter(roster => { const startDate = new Date(roster.startDate.$date); const endDate = new Date(roster.endDate.$date); return startDate <= today && endDate >= today && roster.prep === prep; }).map((item, index, filteredRosters) => (
                                                                                    <RosterAssignmentData
                                                                                        key={item._id.$oid} // Assuming item._id is unique
                                                                                        item={item}
                                                                                        element={element}
                                                                                        isLastItem={index === filteredRosters.length - 1}/>
                                                                                ))}
                                                                            </div>

                                                                        </div>
                                                                    </div> :
                                                                    <div className={'mb-1'}>
                                                                        <button
                                                                            onClick={() => handleAssignmentClick(element)}
                                                                            className={'text-[1rem] w-full rounded-t rounded-bl px-1 py-2 select-none bg-columbia_blue-900 cursor-pointer hover:bg-columbia_blue-800 shadow-md text-dutch_white-400'}>
                                                                            <p className={'font-bold'}>{element.title}</p>
                                                                            <p className={'text-[0.6rem]'}>{element.standardTitle}</p></button>
                                                                        <div className={'flex'}>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex flex-col w-[80%]'}>
                                                                                {(rosters && Array.isArray(rosters)) && rosters.filter(roster => { const startDate = new Date(roster.startDate.$date); const endDate = new Date(roster.endDate.$date); return startDate <= today && endDate >= today && roster.prep === prep; }).map((item, index, filteredRosters) => (
                                                                                    <RosterAssignmentData
                                                                                        key={item._id.$oid} // Assuming item._id is unique
                                                                                        item={item}
                                                                                        element={element}
                                                                                        isLastItem={index === filteredRosters.length - 1}/>
                                                                                ))}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                : <div
                                                                    className={'text-[12px] rounded p-1 select-none bg-platinum-700 shadow-md text-dutch_white-400 mb-2'}>{(element.type !== 'Recall') ? element.text : element.title}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </>)
                                                    :
                                                    <>
                                                        <button onClick={() => setChangeUnitModal(prep)}
                                                             className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700 col-start-2'}>Choose
                                                            Your Current Unit
                                                        </button>
                                                        <p className={'my-auto'}>or</p>
                                                        <button onClick={() => setCreateUnitModal(prep)}
                                                             className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700'}>Create
                                                            a New Unit
                                                        </button>
                                                    </>}
                                            </div>
                                            : (unitPlans.filter(item => item.prep === prep).length > 0) ?
                                                <div className={'flex flex-col gap-4 h-full w-full'}>
                                                    <div className={'grow'}/>
                                                    <div className={'flex gap-6'}>
                                                        <div className={'grow'}/>

                                                        <button onClick={() => setChangeUnitModal(prep)}
                                                                className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700 col-start-2'}>Choose
                                                            Your Current Unit
                                                        </button>
                                                        <p className={'my-auto'}>or</p>
                                                        <button onClick={() => setCreateUnitModal(prep)}
                                                                className={'p-2 bg-columbia_blue-600 rounded cursor-pointer h-fit my-auto shadow hover:bg-columbia_blue-700'}>Create
                                                            a New Unit
                                                        </button>
                                                        <div className={'grow'}/>

                                                    </div>
                                                    <div className={'grow'}/>


                                                </div> : <button onClick={() => setCreateUnitModal(prep)}
                                                                 className={'p-2 bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer text-dutch_white-300 font-bold h-fit w-fit mx-auto mt-[150px] shadow col-start-3'}>Create
                                                New Unit</button>
                                        }
                                        </div>
                                        </div>}
                                    <div className={'grow'}></div>
                                    {(user.userContext.currentUnits[prep]) &&
                                        <div className={'m-2 flex text-dutch_white-300 font-bold'}>
                                            <div className={'grow'}/>
                                            <button onClick={() => setCreateUnitModal(prep)}
                                                    className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Create New Unit</button>
                                        <div className={'grow'} />
                                        <button onClick={() => setAdjustUnitModal(unitPlans.find(item => item._id.$oid === user.userContext.currentUnits[prep]))} className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Adjust This Unit</button>
                                        <div className={'grow'} />
                                        <button onClick={() => setChangeUnitModal(prep)} className={'p-2 w-fit bg-columbia_blue-700 hover:bg-columbia_blue-800 rounded cursor-pointer shadow'}>Select Unit</button>
                                        <div className={'grow'} />
                                    </div>}

                                </div>
                            </div>

                            <div className={'flex flex-col p-4 gap-4 w-[20%] bg-platinum rounded-br rounded-tl bg-opacity-40'}>
                                <p className={'text-columbia_blue-900 -my-2'}>Rosters</p>
                                {(!rosters) ? <div className={'p-8 mx-auto opacity-70'}><div className="border-t-2 border-b-2 border-columbia_blue h-6 w-6 rounded-full animate-spin mx-auto"></div></div> : <>{rosters.filter(roster => { const startDate = new Date(roster.startDate.$date); const endDate = new Date(roster.endDate.$date); return startDate <= today && endDate >= today && roster.prep === prep; }).sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })).map(roster =>
                                    <div className={'rounded flex flex-col bg-dutch_white-300 p-2 shadow border-[1px] border-columbia_blue-800'}>

                                        <p className={'text-columbia_blue-800 font-bold text-xl mb-2 select-none'}>{roster.title}</p>
                                        <div className={'flex gap-2 mx-auto'}>
                                            {(groupData) ? <button className={'p-1 bg-columbia_blue rounded font-bold hover:bg-columbia_blue-600 select-none cursor-pointer shadow h-fit my-auto text-sm text-columbia_blue-900'} onClick={() => setStudentDataModal(roster)}><p className={'my-auto'}>Student Data</p></button> : <div className={'p-2 bg-columbia_blue rounded font-bold hover:bg-columbia_blue-600 select-none cursor-pointer shadow'} ><div className="border-t-2 border-b-2 border-burnt_sienna h-6 w-6 rounded-full animate-spin mx-auto"></div></div>}
                                            {(studentContext) ? <button className={'p-1 bg-burnt_sienna-300 rounded font-bold hover:bg-burnt_sienna-400 select-none cursor-pointer shadow h-fit my-auto text-sm text-columbia_blue-900'} onClick={() => setEditRosterModal(roster)}><p className={'my-auto'}>Edit Roster</p></button> :  <div className={'p-2 bg-burnt_sienna rounded font-bold select-none cursor-pointer shadow'} ><div className="border-t-2 border-b-2 border-columbia_blue h-6 w-6 rounded-full animate-spin mx-auto"></div></div>}
                                        </div>
                                        <button className={'p-1 mt-2 bg-columbia_blue-800 rounded font-bold hover:bg-columbia_blue-700 select-none cursor-pointer shadow h-fit my-auto text-sm text-dutch_white-100'} onClick={() => setGroupModal({roster: roster, chartType: (roster.seatingChart) ? 'Seating Chart' : 'Random'})}>Groups</button>
                                    </div>

                                )}
                                    <button className={'flex items-center bg-columbia_blue-700 p-6 hover:bg-columbia_blue-800 h-6 rounded shadow cursor-pointer w-fit mx-auto'} onClick={() => setNewRosterModal(prep)}>
                                        <p className={'font-bold text-4xl text-dutch_white-300 select-none mb-2'}>+</p>
                                    </button>
                                </>}
                            </div>

                        </div>

                    </div>
                )}
                {(editRosterModal) && <EditRosterModal user={user} studentContext={studentContext} editRosterModal={editRosterModal} rosters={rosters} setRosters={setRosters} setEditRosterModal={setEditRosterModal}/>}
                {(newRosterModal) && <NewRosterModal user={user} newRosterModal={newRosterModal} setRosters={setRosters} setNewRosterModal={setNewRosterModal}/>}
                {(groupModal) && <GroupModal groupData={groupData} standardSets={standardSets} groupModal={groupModal} setGroupModal={setGroupModal} standardElements={knowShowCharts} user={user}/>}
                {(studentDataModal) && <StudentDataModal user={user} groupData={groupData} standardSets={standardSets} studentDataModal={studentDataModal} setStudentDataModal={setStudentDataModal} standardElements={knowShowCharts}/>}
                {(createUnitModal) && (assessmentItems) && (standardSets) && (knowShowCharts) && <CreateUnitModal setUnitPlans={setUnitPlans} user={user} prep={createUnitModal} setCreateUnitModal={setCreateUnitModal} standardSets={standardSets} assessmentItems={assessmentItems} knowShowCharts={knowShowCharts} getUnitPlans={getUnitPlans}/>}
                {(changeUnitModal) && (Object.keys(user).includes('userContext') && <ChangeUnitModal user={user} prep={changeUnitModal} setChangeUnitModal={setChangeUnitModal} unitPlans={unitPlans}/>)}
                {(adjustUnitModal) && (assessmentItems) && (standardSets) && (knowShowCharts) && <AdjustUnitModal setUnitPlans={setUnitPlans} user={user} unit={adjustUnitModal} setAdjustUnitModal={setAdjustUnitModal} standardSets={standardSets} assessmentItems={assessmentItems} knowShowCharts={knowShowCharts} getUnitPlans={getUnitPlans}/>}
                {(viewAssessmentItemsModal) && <ViewAssessmentItemsModal rosters={rosters} resetFunction={fetchRosters} user={user} viewAssessmentItemsModal={viewAssessmentItemsModal} setViewAssessmentItemsModal={setViewAssessmentItemsModal}/>}
                {(assignRecallModal) && <AssignRecallModal user={user} assignRecallModal={assignRecallModal} rosters={rosters} setAssignRecallModal={setAssignRecallModal} />}
                {(isLoading) && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"><div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-columbia_blue-700"></div></div>}
            </div>
        );
    }}

export default Logistics;