import React from 'react';
import DOMPurify from "dompurify";

function RecallHoverStudentTooltip({data}) {
    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={"bg-columbia_blue-500 p-1 rounded text-xs"}>
            <p className={'text-dutch_white-900 font-bold text-lg'}>{data.name}'s Response:</p>
            <div className={"bg-columbia_blue-500 rounded"}>
                <div className={'flex'}>
                    <div
                        className={"bg-dutch_white-200 p-2 border-dutch_white-300 border-2 mt-3 grid grid-cols-2 gap-4 rounded gap-2"}>
                        {data.answer.recallItems.map((item) =>
                            <div className={'flex'}>
                                <div
                                    className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded font-bold h-fit my-auto'}>
                                    {(item.prompt.type === 'image') ? <img src={item.prompt.content}
                                                                           className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/> :
                                        <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>}
                                </div>
                                <div
                                    className={'grow border-b-4 border-dotted border-columbia_blue-800 h-1/2 min-w-[20px]'}/>
                                {(item.active.correct) ? <div
                                        className={'border-dutch_white-800 border-2 flex shadow bg-columbia_blue-700 text-dutch_white-100 p-1 rounded font-bold h-fit my-auto'}>
                                        <svg
                                            width={18}
                                            height={18}
                                            viewBox="0 0 24 24"
                                            fill="#fff"
                                            className={'my-auto mr-2'}
                                        >{/* Added fill to rectangle */}
                                            <path d="M4 12 L8 16 L16 8" stroke="#f6f0e0" strokeWidth="3.5" fill="none"/>
                                            {/* Centered the checkmark */}
                                        </svg>
                                        {(item.active.response.includes('https://ucarecdn')) ?
                                            <img src={item.active.response}
                                                 className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/>
                                            : <div className={'max-w-[200px]'}
                                                   dangerouslySetInnerHTML={renderHtml(item.active.response)}/>}
                                    </div> :
                                    <div
                                        className={'border-dutch_white-800 flex border-2 shadow bg-burnt_sienna-300 text-dutch_white-900 p-1 rounded font-bold h-fit my-auto'}>
                                        <svg className={'my-auto mr-2'} width="18"
                                             height="18"
                                             viewBox="0 0 24 24">
                                            <path fill="#000000"
                                                  d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                        </svg>
                                        {(item.active.response.includes('https://ucarecdn')) ?
                                            <img src={item.active.response}
                                                 className={"rounded max-h-[100px] mx-auto my-auto mb-2 shadow"}/>
                                            : <div className={'max-w-[200px] my-auto'}
                                                   dangerouslySetInnerHTML={renderHtml(item.active.response)}/>}
                                    </div>

                                }
                                {/*{(item.response.content === data.question.matchSets.find(entry => entry.prompt.content === item.prompt.content).response.content) ?*/}
                                {/*    <div*/}
                                {/*        className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded h-fit my-auto'}>*/}
                                {/*        {(item.response.type === 'image') ? <img src={item.response.content}*/}
                                {/*                                                 className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/> :*/}
                                {/*            <div dangerouslySetInnerHTML={renderHtml(item.response.content)}/>}*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div*/}
                                {/*        className={'border-dutch_white-800 border-2 shadow bg-burnt_sienna-500 text-dutch_white-200 p-1 rounded h-fit my-auto'}>*/}
                                {/*        {(item.response.type === 'image') ? <img src={item.response.content}*/}
                                {/*                                                 className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/> :*/}
                                {/*            <div dangerouslySetInnerHTML={renderHtml(item.response.content)}/>}*/}
                                {/*    </div>}*/}
                            </div>)}
                    </div>
                </div>

            </div>

        </div>
    )
        ;
}

export default RecallHoverStudentTooltip;