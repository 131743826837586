import { React, useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

function MatchingEntry({ questionModalContent, selectedAnswer, setSelectedAnswer }) {
    let content = { prompt: DOMPurify.sanitize(questionModalContent.prompt) };
    let [matchPairs, setMatchPairs] = useState(
        questionModalContent.matchData[0].map((item) => ({ prompt: item, response: null }))
    );
    let [selectedResponse, setSelectedResponse] = useState(null);

    // Refs for focus trapping
    const responseContainerRef = useRef(null);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const handleSpaceClick = (index) => {
        let tempMatchPairs = [...matchPairs];
        tempMatchPairs[index].response = selectedResponse;
        setMatchPairs(tempMatchPairs);
        setSelectedResponse(null); // Clear selected response after assigning
        if (!tempMatchPairs.map((item) => item.response).includes(null)) {
            setSelectedAnswer(tempMatchPairs);
        } else {
            setSelectedAnswer(null);
        }

        // Focus the first unselected response
        const firstUnselectedIndex = tempMatchPairs.findIndex((item) => item.response === null);
        if (firstUnselectedIndex !== -1) {
            const focusableEls = responseContainerRef.current.querySelectorAll('button');
            focusableEls[0].focus(); // Focus the first unselected button
        }
    };

    // Focus trap logic
    const trapFocus = (e) => {
        if (e.key === 'Tab' && selectedResponse) {
            const focusableEls = responseContainerRef.current.querySelectorAll('button');
            const firstFocusableEl = focusableEls[0];
            const lastFocusableEl = focusableEls[focusableEls.length - 1];

            if (e.shiftKey) {
                // Shift + Tab
                if (document.activeElement === firstFocusableEl) {
                    e.preventDefault();
                    lastFocusableEl.focus();
                }
            } else {
                // Tab key
                if (document.activeElement === lastFocusableEl) {
                    e.preventDefault();
                    firstFocusableEl.focus();
                }
            }
        }
    };

    useEffect(() => {
        if (selectedResponse) {
            const focusableEls = responseContainerRef.current.querySelectorAll('button');
            if (focusableEls.length > 0) {
                focusableEls[0].focus(); // Focus the first element in the list
            }

            // Add event listener to trap focus when there's a selected response
            document.addEventListener('keydown', trapFocus);
        } else {
            // Remove the event listener if no response is selected
            document.removeEventListener('keydown', trapFocus);
        }

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', trapFocus);
        };
    }, [selectedResponse]);

    return (
        <div className={'p-2 bg-columbia_blue-300'}>
            {questionModalContent.imageURL && (
                <img
                    src={questionModalContent.imageURL}
                    className={
                        'rounded w-80 mx-auto mb-2 p-2 bg-dutch_white-200 shadow border-dutch_white-600 border-2'
                    }
                />
            )}
            <div
                className={
                    'bg-dutch_white-200 text-lg p-4 rounded text-dutch_white-800 border-dutch_white-600 border-2 shadow'
                }
            >
                <div dangerouslySetInnerHTML={renderHtml(content.prompt)} />
            </div>
            <div className={'flex'}>
                <div className={'flex flex-col w-3/4 mx-auto p-2'} ref={responseContainerRef}>
                    {matchPairs.map((item, index) => (
                        <div className={'bg-paynes_gray-600 rounded shadow my-2 px-4 flex py-2'} key={index}>
                            <div
                                className={
                                    'bg-columbia_blue-700 text-dutch_white-100 p-2 border-gray-500 border-1 rounded w-fit text-2xl h-fit my-auto mr-2'
                                }
                            >
                                {item.prompt.type === 'text' && (
                                    <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />
                                )}
                                {item.prompt.type === 'image' && (
                                    <img
                                        draggable={false}
                                        src={item.prompt.content}
                                        className={'rounded max-h-[100px]'}
                                    />
                                )}
                            </div>
                            <div className={'grow h-[50%] border-columbia_blue-200 border-b-4 border-dotted'} />
                            {item.response ? (
                                <button
                                    className={
                                        'bg-dutch_white-300 p-2 border-1 my-3 rounded w-fit text-2xl shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer hover:bg-burnt_sienna-400 ml-2'
                                    }
                                    onClick={() => handleSpaceClick(index)}
                                >
                                    {item.response.type === 'text' ? (
                                        <div className={'text-black'} dangerouslySetInnerHTML={renderHtml(item.response.content)} />
                                    ) : (
                                        <img
                                            draggable={false}
                                            src={item.response.content}
                                            className={'rounded max-h-[100px]'}
                                        />
                                    )}
                                </button>
                            ) : selectedResponse ? (
                                <button
                                    className={
                                        'bg-dutch_white-300 py-6 border-1 my-3 border-columbia_blue-100 text-2xl shadow-inner shadow-3xl w-[40%] border-columbia_blue-600 border-2 rounded-full'
                                    }
                                    onClick={() => handleSpaceClick(index)}
                                />
                            ) : (
                                <div
                                    className={
                                        'bg-dutch_white-300 py-6 border-1 my-3 text-2xl shadow-inner shadow-3xl w-[40%] border-columbia_blue-600 border-2 rounded-full'
                                    }
                                />
                            )}
                        </div>
                    ))}
                </div>
                {questionModalContent.matchData[1]
                    .filter((item) => !matchPairs.map((element) => element.response).includes(item)).length > 0 && (
                    <div className={'flex flex-col gap-4 mx-auto w-1/4 overflow-auto grow max-h-full'}>
                        {questionModalContent.matchData[1]
                            .filter((item) => !matchPairs.map((element) => element.response).includes(item))
                            .map((item, index) => (
                                <button
                                    className={
                                        item === selectedResponse
                                            ? 'bg-columbia_blue-600 text-dutch_white-100 p-2 my-3 border-dutch_white-200 border-2 rounded w-fit text-2xl cursor-pointer hover:bg-columbia_blue-600 mx-auto'
                                            : 'bg-dutch_white-300 text-dutch_white-900 p-2 border-platinum-700 border-2 my-3 rounded w-fit text-2xl cursor-pointer hover:bg-columbia_blue-600 mx-auto'
                                    }
                                    onClick={() => setSelectedResponse(item)}
                                    key={index}
                                >
                                    {item.type === 'text' && <div dangerouslySetInnerHTML={renderHtml(item.content)} />}
                                    {item.type === 'image' && (
                                        <img draggable={false} src={item.content} className={'rounded max-h-[100px]'} />
                                    )}
                                </button>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default MatchingEntry;