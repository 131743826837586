import React, {useState} from 'react';
import DOMPurify from "dompurify";
import RecallHoverItemTooltip from "./recallHoverItemTooltip";
import RecallHoverStudentTooltip from "./recallHoverStudentTooltip";

function RecallSummaryAnswerDistribution({ recallItems, studentData }) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoveredStudent, setHoveredStudent] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: -15, y: 0 });

    const handleMouseMove = (event) => {
        setMousePosition({ x: event.clientX - 250, y: event.clientY - 50});
    };
    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={'grid grid-cols-4 gap-2 p-2 bg-columbia_blue-800 rounded-b rounded-tr'}>
            <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Perfect Match</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {studentData.filter(entry => entry.data).filter(entry => !entry.data.recallItems.flatMap(item => item.active.correct).includes('pending') && !entry.data.recallItems.flatMap(item => item.active.correct).includes(false))
                        .map(item => (
                            <div className={'p-1 bg-dutch_white-400 text-burnt_sienna-900 rounded'}>
                                <p>{item.firstName} {item.lastName} </p>
                            </div>))}
                    {studentData.filter(entry => entry.data).filter(entry => entry.data.recallItems.flatMap(item => item.active.correct).includes(true) && entry.data.recallItems.flatMap(item => item.active.correct).includes('pending') && !entry.data.recallItems.flatMap(item => item.active.correct).includes(false))
                        .map(entry => (
                            <div className={'p-1 bg-dutch_white-400 text-burnt_sienna-900 rounded text-sm'}>
                                <p>{entry.firstName} {entry.lastName} </p>
                                <p>{entry.data.recallItems.flatMap(item => item.active.correct).filter(item => item === 'pending').length} pending</p>
                            </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Mismatched</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {[...new Set(studentData.filter(entry => entry.data).filter(entry => entry.data.recallItems.flatMap(item => item.active.correct).filter(item => item === false).length > 0)
                        .map(entry => entry.data.recallItems.flatMap(item => item.active.correct).filter(item => item === false).length)
                    )
                    ].sort((a, b) => b - a) // Sort the unique values in ascending order
                        .map(result => (
                            <div key={result} className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded mb-2'}>
                                <p className={'font-bold underline mb-2'}>{result} mismatches</p>
                                {studentData.filter(entry => entry.data).filter(entry => entry.data.recallItems.flatMap(item => item.active.correct).filter(item => item === false).length === result).map(student =>
                                    <div className={'p-1 bg-burnt_sienna-300 text-dutch_white-900 rounded mb-1'}
                                         onMouseEnter={() => setHoveredStudent({
                                             answer: student.data,
                                             name: `${student.firstName} ${student.lastName}`,
                                         })}
                                         onMouseLeave={() => setHoveredStudent(null)}
                                         onMouseMove={handleMouseMove}>
                                        <p>{student.firstName} {student.lastName}</p>
                                    </div>)}
                            </div>
                        ))}
                    {(hoveredStudent) &&
                        <div
                            className="absolute bg-white p-2 shadow rounded opacity-95 z-10"
                            style={{left: mousePosition.x, top: mousePosition.y - 10, // Adjust the y-coordinate to avoid overlapping the mouse
                                transform: 'translateY(-100%)'}}>
                            <RecallHoverStudentTooltip data={hoveredStudent}/>
                        </div>}

                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Prompt Mismatches:</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2 text-left'}>
                    {recallItems.sort((a, b) => studentData.filter(entry => entry.data).flatMap(entry => entry.data.recallItems)
                        .filter(entry => entry.prompt.content === b.prompt.content && entry.active.correct === false)
                        .length - studentData.filter(entry => entry.data).flatMap(entry => entry.data.recallItems)
                        .filter(entry => entry.prompt.content === a.prompt.content && entry.active.correct === false)
                        .length).map(item =>
                        <div onMouseEnter={() => setHoveredItem({item: item, names: studentData.filter(entry => entry.data).filter(entry => (entry.data.recallItems.map(item => item.prompt.content).includes(item.prompt.content)) && !entry.data.recallItems.find(entry => entry.prompt.content === item.prompt.content).active.correct).map(student => `${student.firstName} ${student.lastName}`)})}
                             onMouseLeave={() => setHoveredItem(null)}
                             onMouseMove={handleMouseMove} className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>
                            <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)} /><div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>{studentData.filter(entry => entry.data).flatMap(entry => entry.data.recallItems)
                            .filter(entry => entry.prompt.content === item.prompt.content && entry.active.correct === false)
                            .length}</div></div>)}
                    {(hoveredItem) &&
                        <div
                            className="absolute bg-white p-2 shadow rounded opacity-95 z-10"
                            style={{left: mousePosition.x, top: mousePosition.y - 10, // Adjust the y-coordinate to avoid overlapping the mouse
                                transform: 'translateY(-100%)'}}>
                            <RecallHoverItemTooltip data={hoveredItem}/>
                        </div>}
                </div>
            </div>
            <div
                className={'rounded bg-platinum-800 text-dutch_white-400 h-full shadow p-2 flex flex-col gap-1'}>
                {<>
                    <p className={'font-bold'}>Pending</p>
                    <div
                        className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2 text-sm'}>
                        {studentData
                            .filter(entry => entry.data).filter(entry => !entry.data.recallItems.flatMap(item => item.active.correct).includes(true) && !entry.data.recallItems.flatMap(item => item.active.correct).includes(false))
                            .map(student =>
                                <div
                                    className={'p-1 bg-platinum-700 text-platinum-400 px-4 rounded'}>
                                    <p>{student.firstName} {student.lastName} </p>
                                </div>)}
                    </div>
                </>}
            </div>
        </div>
    );
}

export default RecallSummaryAnswerDistribution;