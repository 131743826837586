import React from 'react';
import {PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip} from 'recharts';
import DOMPurify from "dompurify";

const renderHtml = (htmlString) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
    return { __html: sanitizedHtml };
};

const GradeLevelPie = ({ data }) => {


    const pieData = [
        { name: 'Correct', value: data.correct },
        { name: 'Incorrect', value: data.incorrect },
        { name: 'Pending', value: data.pending },
    ];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '10px',
                        fontSize: '0.9rem',
                        maxWidth: '200px',
                        wordWrap: 'break-word', // Ensure long text wraps
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{payload[0].name}</p>
                    <p style={{ margin: 0 }}>Value: {payload[0].value.toFixed(2)}</p>
                </div>
            );
        }
        return null;
    };

    const CustomLabel = ({
                             cx,
                             cy,
                             midAngle,
                             innerRadius,
                             outerRadius,
                             percent,
                             index,
                             name,
                         }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    textShadow: '0px 0px 6px rgba(0,0,0,1)', // Add shadow for readability
                }}
            >
                {`${name} ${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    // Define colors for each segment
    const COLORS = ['#538ea5', '#D96B3F', '#8b8b8b'];

    return (
        <div style={{ position: 'relative', width: '100%', height: '300px' }}>
            {/* Responsive container for dynamic sizing */}
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    {/* Donut chart */}
                    <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        innerRadius="50%"
                        outerRadius="65%"
                        paddingAngle={3}
                        dataKey="value"
                        label={(props) => <CustomLabel {...props} />}
                        labelLine={false}
                        stroke={'#efefef'}  // Set the outline color
                        strokeWidth={1}  // Set the outline width
                    >
                        {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                    </Pie>

                    {/* Tooltip for interactivity */}
                    <Tooltip
                        content={<CustomTooltip />}
                        wrapperStyle={{
                            zIndex: 1000, // Ensure it stays above other content
                            overflow: 'visible', // Allow text to flow outside the container
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>

            {/* Total assessments in the middle */}
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    color: '#f2f7f9'
                }}
            >
                <p style={{fontSize: '1.8rem'}}>{data.totalAssessmentsEntries}</p>
                <p>assessments</p>

            </div>
        </div>
    );
};

export default GradeLevelPie;



