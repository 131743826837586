import React from 'react';
import {useNavigate} from "react-router-dom";
import {signOut} from "aws-amplify/auth";

function TeacherNavBar() {
    const navigate = useNavigate()

    return (
        <div className={'relative w-full flex justify-between bg-columbia_blue-800 border-dutch_white-600 border-b-4 mb-1 z-10'}>
            <div className={"hover:bg-columbia_blue-700 px-4"}>
                <img onClick={() => navigate('/coordinate')} className={'h-[60px] w-[60px] cursor-pointer my-auto'}
                     src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'} alt={'Home'}/>

            </div>
            <div
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/administration')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Administration</p>
            </div>
            <div
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/designcoord')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Design</p>
            </div>
            <div
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/dashboardcoord')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Dashboard</p>
            </div>
            <div
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/datacoord')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Data</p>
            </div>
            <div
                className={'flex items-center  max-w-min py-1 px-8 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 border-x border-dutch_white-800 align-items-middle'}
                onClick={() => signOut()}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Sign Out</p>
            </div>
        </div>
    );
}

export default TeacherNavBar;