import React from 'react';
import GradeLevelPie from "./gradeLevelPie";
import GradeLevelBarChart from "./gradeLevelBarChart";
import GradeLevelMTSSArray from "./gradeLevelMTSSArray";

function GradeLevelSummaryBlock({data}) {
    return (
        <div className={'grow flex-col bg-columbia_blue-800 m-1 rounded h-full p-2'}>
            <p className={'font-bold text-columbia_blue-100 text-3xl'}>Grade {data.grade}</p>
            <GradeLevelPie data={data.pie} />
            <GradeLevelBarChart data={data.bar} />
            <GradeLevelMTSSArray data={data.mtss} />
        </div>
    );
}

export default GradeLevelSummaryBlock;