import React, {useState} from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";


const BarWithBorder = (props) => {
    const { fill, x, y, width, height } = props;
    return (
        <g tabIndex={0} onClick={props.onClick}>
            <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
            <rect x={x} y={y} width={width} height={2} stroke="none" fill={'white'} />
        </g>
    );
};

const CustomLabelCheck = ({ x, y, width, height, value }) => {
    if (height > 0) {
        return (
            <svg
                x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
                y={y + height - 28}      // Position the label just above the bottom of the bar
                width={30}
                height={30}
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
            >{/* Added fill to rectangle */}
                <path d="M4 12 L8 16 L16 8" stroke="#f6f0e0" strokeWidth="1.5" fill="none"/>
                {/* Centered the checkmark */}
            </svg>
        );
    }
};

const CustomLabelX = ({ x, y, width, height, value }) => {
    if (height > 0) {
        return (
            <svg
                x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
                y={y + height - 20}      // Position the label just above the bottom of the bar
                width={30}
                height={30}
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2">
                <path d="M5 2 L13 10 M13 2 L5 10" stroke="#f6f0e0" strokeWidth="1.5" fill="none"/>
                {/* Centered the checkmark */}
            </svg>
        );
    }
};

const CustomLabelPending = ({ x, y, width, height }) => {
    if (height > 0) {
        return (
                <svg
                    x={x + width / 2 - 10}  // Center the label horizontally relative to the bar's width
                    y={y + height - 25}      // Position the label just above the bottom of the bar
                    width={30}
                    height={30}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#f6f0e0"
                    strokeWidth="1">
                    <text
                        x="39%"                 // Center the text horizontally
                        y="70%"                 // Adjust the vertical position to fit above the bar
                        textAnchor="middle"     // Center the text anchor
                        fill="#f6f0e0"            // Set the fill color for the text
                        fontSize="16"           // Set the font size
                    >
                        ?
                    </text>
                </svg>
            );
        }
    };

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    fontSize: '0.9rem',
                    maxWidth: '200px',
                    wordWrap: 'break-word', // Ensure long text wraps
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >
                <p style={{margin: 0, fontWeight: 'bold'}}>{payload[0].payload.course}</p>
                <p style={{margin: 0, fontWeight: 'bold'}}>{payload[0].payload.count} assessments</p>

                {(payload[0].payload.correctFrequency) ?
                    <p>Correct : {(payload[0].payload.correctFrequency * 100).toFixed(2)} %</p> : <p>Correct: 0%</p>}
                {(payload[0].payload.incorrectFrequency) ?
                    <p>Incorrect : {(payload[0].payload.incorrectFrequency * 100).toFixed(2)} %</p> :
                    <p>Incorrect: 0%</p>}
                {(payload[0].payload.pendingFrequency) ?
                    <p>Pending : {(payload[0].payload.pendingFrequency * 100).toFixed(2)} %</p> : <p>Pending: 0%</p>}

            </div>
        );
    }
    return null;
};


export default function GradeLevelBarChart({data}) {

    return (
        <div className={'w-full'}>
            <ResponsiveContainer width='100%' height={300}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#eeeeee"/>
                    {/*<XAxis dataKey='course' className={'font-bold'}*/}
                    {/*       tick={{ fontSize: 9 }}  // Adjust tick label font size if needed*/}
                    {/*       tickLine={false}*/}
                    {/*angle={90}/>*/}

                    {/*<YAxis stroke="#eeeeee" />*/}
                    <Tooltip
                        content={<CustomTooltip />}
                        wrapperStyle={{
                        zIndex: 1000, // Ensure it stays above other content
                        overflow: 'visible', // Allow text to flow outside the container
                    }}
                    />
                    <Bar dataKey="correctFrequency" stackId="a" fill='#538ea5' shape={<BarWithBorder />} label={<CustomLabelCheck />}/>
                    <Bar dataKey="incorrectFrequency" stackId="a" fill="#D96B3F" shape={<BarWithBorder />} label={<CustomLabelX />}/>
                    <Bar dataKey="pendingFrequency" stackId="a" fill="#8b8b8b" label={<CustomLabelPending />}/>

                </BarChart>
            </ResponsiveContainer>
        </div>

    );
}
