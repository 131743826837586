import React, {useContext, useEffect, useState} from 'react';
import Data from "../../teacher/data/data";
import getWithAuth from "../../context/getWithAuth";
import userContext from "../../context/userContext";

function DataFetchLayerCoord() {
    const [rosters, setRosters] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const user = useContext(userContext);

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getStandardSets`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getRosters`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching roster data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getKnowShow`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standard elements data:', error)
            }
        }
    }

    const fetchAssessmentItems = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getAssessmentItems`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching assessment item data:', error)
            }
        }
    }

    const fetchStudentData = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getStudentData`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setStudentData(r)
                    })
            } catch (error) {
                console.error('Error fetching student data:', error)
            }
        }

    }

    useEffect(() => {
        fetchRosters();
        fetchStandardSets();
        fetchKnowShowCharts();
        fetchAssessmentItems();
    }, [user.userContext]);

    useEffect(() => {
        if (rosters && Array.isArray(rosters)) {
            let idArr = rosters.flatMap(item => item.students);
            fetchStudentData(idArr);

            let intervalId;

            const startInterval = () => {
                if (!intervalId) {
                    intervalId = setInterval(() => fetchStudentData(idArr), 15000);
                }
            };

            const stopInterval = () => {
                if (intervalId) {
                    clearInterval(intervalId);
                    intervalId = null;
                }
            };

            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    startInterval();
                } else {
                    stopInterval();
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            // Start the interval initially if the page is visible
            if (document.visibilityState === 'visible') {
                fetchStudentData(idArr); // Fetch data immediately
                startInterval();
            }

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                stopInterval();
            };
        }
    }, [rosters]);

    return (
        <Data user={user} rosters={rosters} setRosters={setRosters} standardSets={standardSets} setStandardSets={setStandardSets} knowShowCharts={knowShowCharts} setKnowShowCharts={setKnowShowCharts} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} studentData={studentData} setStudentData={setStudentData} fetchStudentData={fetchStudentData}/>
    );
}

export default DataFetchLayerCoord;