import React, {useContext, useEffect, useState} from 'react';
import Data from "./data";
import getWithAuth from "../../context/getWithAuth";
import userContext from "../../context/userContext";

function DataFetchLayer() {
    const [rosters, setRosters] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const [archiveData, setArchiveData] = useState(null);
    const user = useContext(userContext);

    const fetchRosters = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/data/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/data/getStandardSets`, user.userAttributes['custom:school'], {id: user.userContext._id.$oid})
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/data/getKnowShowCharts`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/data/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentData = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/data/getStudentData`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        let data = JSON.parse(r)
                        setStudentData(data.currentData)
                        setArchiveData(data.archiveData)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchRosters();
        fetchStandardSets();
        fetchKnowShowCharts();
        fetchAssessmentItems();
    }, [user.userContext]);

    useEffect(() => {
        if (user.userContext) {
            fetchStudentData();

            let intervalId;

            const startInterval = () => {
                if (!intervalId) {
                    intervalId = setInterval(() => fetchStudentData(), 15000);
                }
            };

            const stopInterval = () => {
                if (intervalId) {
                    clearInterval(intervalId);
                    intervalId = null;
                }
            };

            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    startInterval();
                } else {
                    stopInterval();
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            // Start the interval initially if the page is visible
            if (document.visibilityState === 'visible') {
                fetchStudentData(); // Fetch data immediately
                startInterval();
            }

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                stopInterval();
            };
        }
    }, [user.userContext]);

    return (
        <Data user={user} rosters={rosters} setRosters={setRosters} standardSets={(user.userContext && standardSets) && standardSets.filter(standardSet => user.userContext.preps.includes(standardSet.prepTitle))} setStandardSets={setStandardSets} knowShowCharts={knowShowCharts} setKnowShowCharts={setKnowShowCharts} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} studentData={studentData} setStudentData={setStudentData} fetchStudentData={fetchStudentData}/>
    );
}

export default DataFetchLayer;