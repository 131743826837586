import React, {useEffect, useRef, useState} from 'react';
import StudentDataSummaryModal from "./studentDataSummaryModal";

function StudentDataModal({ user, groupData, standardSets, studentDataModal, setStudentDataModal, standardElements}) {
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [studentDataSummaryModal, setStudentDataSummaryModal] = useState(null);
    const onClose = () => {
        setStudentDataModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper2" ) onClose();
    }

    const modalRef = useRef(null);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (!modalElement) return;

        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        // Focus the first focusable element when the modal opens
        if (firstFocusableElement) firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                onClose();
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            modalElement.removeEventListener('keydown', trapFocus);
        };
    }, [setStudentDataModal, studentDataSummaryModal, setStudentDataSummaryModal]);
    let standards = standardSets.find(item => item.prepTitle === studentDataModal.prep).standards.filter(standard => studentDataModal.assessmentHistory.flatMap(item => item.knowShowId).some(element => new Set(standardElements.filter(chart => chart.standard === standard.standardCode).flatMap(item => item._id.$oid)).has(element)))
    // let standards = standardSets.find(item => item.prepTitle === studentDataModal.prep).standards.filter(standard => groupData.filter(item => studentDataModal.students.includes(item.studentId)).flatMap(entry => entry.active[studentDataModal.prep]).flatMap(entry => entry.standard).includes(standard.standardCode)).filter(standard => studentDataModal.assessmentHistory.flatMap(item => item.standard).includes(standard.standardCode))
    let studentDataArray = groupData.filter(item => studentDataModal.students.includes(item.studentId)).map(entry => ({firstName: entry.firstName, lastName: entry.lastName, active: entry.active[studentDataModal.prep]}))
    const sortedStudents = groupData.filter(item => studentDataModal.students.includes(item.studentId))
        .map((item) => {


            let correctCount = 0;
            let totalCount = 0;

            if (selectedStandard !== null) {
                const activeDataEntries = item.active[studentDataModal.prep].filter(entry => (entry.type === 'Recall') || (Object.keys(entry).includes('dateAssigned') && (new Date(entry.dateAssigned.$date) >= new Date(studentDataModal.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(studentDataModal.endDate.$date)))).filter(item => item.standard === selectedStandard)
                // Count items with result true or result 0
                const resultCount = activeDataEntries.filter(entry => entry.standard === selectedStandard && (entry.result === true || entry.result === 0) && (entry.result !== 'pending')).length;

                // Check for Recall type entries and calculate correct recall items
                const recallEntries = activeDataEntries.filter(entry => entry.standard === selectedStandard && entry.type === 'Recall');
                if (recallEntries.length > 0) {
                    const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                    const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                    const totalRecallItems = recallItems.map(entry => entry.active).length;
                    if (totalRecallItems > 0) {
                        correctCount = resultCount + (correctRecallCount / totalRecallItems);
                    } else {
                        correctCount = resultCount;
                    }
                } else {
                    correctCount = resultCount;
                }
                totalCount = item.active[studentDataModal.prep].filter(entry => (entry.type === 'Recall') || (Object.keys(entry).includes('dateAssigned') && (new Date(entry.dateAssigned.$date) >= new Date(studentDataModal.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(studentDataModal.endDate.$date)))).filter(item => item.standard === selectedStandard).length
            } else {
                const activeDataEntries = item.active[studentDataModal.prep].filter(entry => (entry.type === 'Recall') || (Object.keys(entry).includes('dateAssigned') && (new Date(entry.dateAssigned.$date) >= new Date(studentDataModal.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(studentDataModal.endDate.$date))));

                // Count items with result true or result 0
                const resultCount = activeDataEntries.filter(entry => (entry.result === true || entry.result === 0) && (entry.result !== 'pending')).length;

                // Check for Recall type entries and calculate correct recall items
                const recallEntries = activeDataEntries.filter(entry => entry.type === 'Recall');
                if (recallEntries.length > 0) {
                    const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                    const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                    const totalRecallItems = recallItems.map(entry => entry.active).length;

                    if (totalRecallItems > 0) {
                        correctCount = resultCount + (correctRecallCount / totalRecallItems);
                    } else {
                        correctCount = resultCount;
                    }
                } else {
                    correctCount = resultCount;
                }
                totalCount = item.active[studentDataModal.prep].filter(entry => (entry.type === 'Recall') || (Object.keys(entry).includes('dateAssigned') && (new Date(entry.dateAssigned.$date) >= new Date(studentDataModal.startDate.$date) && new Date(entry.dateAssigned.$date) <= new Date(studentDataModal.endDate.$date)))).length
            }
            return {
                studentId: item._id.$oid,
                firstName: item.firstName,
                lastName: item.lastName,
                correctCount: correctCount,
                totalCount: totalCount
            };
        })
        .sort((a, b) => b.correctCount / b.totalCount - a.correctCount / a.totalCount);


    const handleStandardChange = (e) => {
        setSelectedStandard(e.target.value)
    }
    const firstHalf = sortedStudents.slice(0, Math.ceil(sortedStudents.length / 2));
    const secondHalf = sortedStudents.slice(
        Math.ceil(sortedStudents.length / 2)
    );
    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper2" onClick={handleClose}>
            <div className={"flex flex-col"} ref={modalRef}>
                <div className={"relative bg-dutch_white-400 m-4 rounded p-2 max-w-[1200px]"}>
                    <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={"bg-columbia_blue-500 p-4 text-black cursor-black rounded max-h-[550px] overflow-y-auto"} >
                        <select className={'bg-columbia_blue-800 p-2 mb-4 rounded text-dutch_white-400 font-bold'} onChange={handleStandardChange} defaultValue={""}>
                            <option disabled value={""}>Choose a Standard for Sorting:</option>
                            {standards.map(item => <option value={item.standardCode} key={item.standardCode}>{item.code} {item.title}</option>)}
                        </select>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-1 flex flex-col">
                                {firstHalf.map((student, index) => (
                                    <button
                                        className={'p-2 mb-1 rounded bg-columbia_blue-800 text-dutch_white-300 text-left font-bold hover:bg-columbia_blue-700 cursor-pointer select-none'}
                                        key={student.studentId}
                                        onClick={() => setStudentDataSummaryModal(groupData.find(entry => entry._id.$oid === student.studentId))}
                                    >
                                        {(student.totalCount > 0) ?
                                            <p>
                                                {index + 1}: {student.firstName} {student.lastName[0]}. {Math.floor(student.correctCount / student.totalCount * 100)}%
                                            </p>
                                            :
                                            <p>
                                                {student.firstName} {student.lastName[0]}.: No Data
                                            </p>}
                                            </button>
                                            ))}
                                    </div>
                                    <div className="col-span-1 flex flex-col">
                                {secondHalf.map((student, index) => (
                                    <button
                                        className={'p-2 mb-1 rounded bg-columbia_blue-800 text-dutch_white-300 text-left font-bold hover:bg-columbia_blue-700 cursor-pointer select-none'}
                                        key={student.studentId}
                                        onClick={() => setStudentDataSummaryModal(groupData.find(entry => entry._id.$oid === student.studentId))}

                                    >
                                        {(student.totalCount > 0) ?
                                        <p>
                                            {firstHalf.length + index + 1}: {student.firstName} {student.lastName[0]}. {Math.floor(student.correctCount / student.totalCount * 100)}%
                                        </p>
                                        :
                                        <p>
                                            {student.firstName} {student.lastName[0]}.: No Data
                                        </p>}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(studentDataSummaryModal) && <StudentDataSummaryModal user={user} studentData={studentDataSummaryModal} studentSummaryModal={studentDataSummaryModal} setStudentSummaryModal={setStudentDataSummaryModal} standardSets={standardSets} standardElements={standardElements} />}
        </div>
    );
}

export default StudentDataModal;