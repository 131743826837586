import React from 'react';

function GradeLevelMTSSArray({ data }) {
    return (
        <div className={'w-full'}>
            <h2 className={'text-lg font-bold text-dutch_white-200'}>MTSS</h2>
            <div className={'grid grid-cols-2 gap-1'}>
                <div className={'flex flex-col mx-auto'}>
                    <p className={'text-dutch_white-200'}>Incorrect</p>
                    {data.sort((b, a) => a.frequencyFalse - b.frequencyFalse).map(student =>
                        <button
                            className={'flex flex-col hover:bg-dutch_white-300 w-full justify-between bg-dutch_white-200 text-columbia_blue-900 m-1 p-2 rounded mx-auto'}>
                            <p className={'mx-auto text-sm'}>{student.firstName} {student.lastName}</p>
                            <div className={'bg-burnt_sienna-400 p-1 rounded px-4 mx-auto'}>
                                <svg className={'mx-auto'} xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                     viewBox="0 0 24 24">
                                    <path fill="#fff"
                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                </svg>
                                <p className={'text-dutch_white-900 text-xs mr-1'}>{Number(student.frequencyFalse).toFixed(2)}%</p>
                            </div>
                        </button>
                    )}
                </div>
                <div className={'flex flex-col mx-auto'}>
                    <p className={'text-dutch_white-200'}>Pending</p>
                    {data.sort((b, a) => a.frequencyPending - b.frequencyPending).map(student =>
                        <button
                            className={'flex flex-col hover:bg-dutch_white-300 w-full bg-dutch_white-200 text-columbia_blue-900 m-1 p-2 rounded mx-auto'}>
                            <p className={'text-sm mx-auto'}>{student.firstName} {student.lastName}</p>
                            <div className={'bg-platinum-600 p-1 rounded px-4 mx-auto'}>
                                <svg className={'mx-auto'}
                                    width={12}
                                    height={12}
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                    stroke="#000000"
                                    strokeWidth="1">
                                    <text
                                        x="39%"                 // Center the text horizontally
                                        y="70%"                 // Adjust the vertical position to fit above the bar
                                        textAnchor="middle"     // Center the text anchor
                                        fill="#000000"            // Set the fill color for the text
                                        fontSize="23"           // Set the font size
                                    >
                                        ?
                                    </text>
                                </svg>
                                <p className={'text-dutch_white-900 text-xs mr-1'}>{Number(student.frequencyPending).toFixed(2)}%</p>
                            </div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GradeLevelMTSSArray;