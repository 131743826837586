import React from 'react';
import DOMPurify from "dompurify";

function MatchingResultView({ questionModalContent, answerView}) {

    let questionHtml = DOMPurify.sanitize(questionModalContent.prompt)

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (<>
            {(answerView.result === 0) ?
                <div className={"bg-columbia_blue-800 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-300 font-bold text-2xl'}>Correct!</p>
                    <p className={'text-dutch_white-300 mb-2'}>Nice! You've mastered this one!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        {(questionModalContent.imageURL) && <img src={questionModalContent.imageURL} className={"rounded w-80 mx-auto mb-2 shadow"} />}
                        <div className={"bg-dutch_white-300 text-lg p-4  border-dutch_white-600 border-2 rounded"}>{<div dangerouslySetInnerHTML={renderHtml(questionHtml)} />}</div>
                        <div className={"bg-columbia_blue-700 p-2 my-3 flex flex-col w-3/4 mx-auto rounded border-columbia_blue-800 border-2 gap-4"}>
                            {answerView.correctResponse.map(item =>
                                <div className={'flex'}>
                                    <div className={'border-dutch_white-400 border-2 shadow bg-columbia_blue-200 text-dutch_white-800 p-1 rounded font-bold w-fit h-fit my-auto'}>
                                        {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                    </div>
                                    <div className={'grow border-b-4 border-dotted border-columbia_blue-800 my-auto min-w-[20px]'} />
                                    <div className={'border-dutch_white-700 border-2 shadow bg-dutch_white-300 text-dutch_white-800 p-1 rounded w-fit h-fit my-auto'}>
                                        {(item.match.type === 'image') ? <img src={item.match.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.match.content)} />}
                                    </div>
                                </div>)}                        </div>
                    </div>
                </div>
                :
                <div className={"bg-burnt_sienna-600 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-200 font-bold text-2xl'}>Incorrect</p>
                    <p className={'mb-2 text-dutch_white-200'}>You've got some learning left to do: review the differences below for next time!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        {(questionModalContent.imageURL) && <img src={questionModalContent.imageURL} className={"rounded w-80 mx-auto mb-2 shadow"} />}
                        <div className={"bg-dutch_white-300 text-lg p-4 border-dutch_white-600 border-2 rounded shadow"}>{<div dangerouslySetInnerHTML={renderHtml(questionHtml)} />}</div>
                        <div className={'flex'}>
                            <div className={"bg-burnt_sienna-200 p-2 border-dutch_white-300 border-2 mt-3 flex flex-col rounded w-[45%] gap-2"}>
                                <p className={'text-lg text-dutch_white-900'}>Your Response:</p>
                                {answerView.studentResponse.sort((a, b) => a.prompt.content.localeCompare(b.prompt.content)).map((item, index) =>
                                    <div className={'flex'}>
                                        <div className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-200 text-dutch_white-900 p-1 rounded font-bold h-fit my-auto'}>
                                            {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                        </div>
                                        <div className={'grow border-b-4 border-dotted border-columbia_blue-800 h-1/2 min-w-[20px]'} />
                                        {(item.response.content === answerView.correctResponse.sort((a, b) => a.prompt.content.localeCompare(b.prompt.content))[index].match.content) ?
                                            <div
                                                className={'border-dutch_white-800 border-2 flex min-w-fit shadow bg-columbia_blue-700 text-dutch_white-200 p-1 rounded h-fit my-auto'}>
                                                <svg
                                                    width={18}
                                                    height={18}
                                                    viewBox="0 0 24 24"
                                                    fill="#fff"
                                                    className={'my-auto mr-2'}
                                                >
                                                    <path d="M4 12 L8 16 L16 8" stroke="#f6f0e0" strokeWidth="3.5"
                                                          fill="none"/>
                                                </svg>
                                                {(item.response.type === 'image') ? <img src={item.response.content}
                                                                                         className={"rounded max-h-[60px] mx-auto mb-2 shadow"}/> :
                                                    <div dangerouslySetInnerHTML={renderHtml(item.response.content)}/>}
                                            </div>
                                            :
                                            <div
                                                className={'border-dutch_white-800 border-2 min-w-fit flex shadow bg-burnt_sienna-400 text-dutch_white-900 p-1 rounded h-fit my-auto'}>
                                                <svg className={'my-auto mr-2'} width="18"
                                                     height="18"
                                                     viewBox="0 0 24 24">
                                                    <path fill="#000000"
                                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                </svg>
                                                {(item.response.type === 'image') ? <img src={item.response.content}
                                                                                         className={"rounded max-h-[60px] mx-auto mb-2 shadow"}/> :
                                                    <div dangerouslySetInnerHTML={renderHtml(item.response.content)}/>}
                                            </div>}
                                    </div>)}
                            </div>
                            <div className={'grow'}/>
                            <div
                                className={"bg-columbia_blue-700 p-2 mt-3 flex flex-col border-dutch_white-300 border-2 rounded w-[45%] gap-2"}>
                                <p className={'text-lg text-dutch_white-200'}>Correct Response:</p>
                                {answerView.correctResponse.sort((a, b) => a.prompt.content.localeCompare(b.prompt.content)).map(item =>
                                    <div className={'flex'}>
                                        <div className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-200 text-dutch_white-900 p-1 rounded font-bold h-fit my-auto'}>
                                            {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                        </div>
                                        <div className={'grow border-b-4 border-dotted border-columbia_blue-800 h-1/2 min-w-[20px]'} />
                                        <div className={'border-dutch_white-800 border-2 shadow bg-dutch_white-300 text-dutch_white-900 p-1 rounded h-fit my-auto'}>
                                            {(item.match.type === 'image') ? <img src={item.match.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.match.content)} />}
                                        </div>
                                    </div>)}
                            </div>
                        </div>

                    </div>

                </div>}
        </>
    );
}

export default MatchingResultView;