import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import getWithAuth from "../../context/getWithAuth";
import Planning from "../../teacher/planning/planning";
import Logistics from "../../teacher/logistics/logistics";
import Loader from "../../components/loader";
import CoordinatorNavBar from "../coordinatorNavBar";

function DesignFetchLayerCoord() {

    const [standardSets, setStandardSets] = useState(null);
    const [rosters, setRosters] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const [teacherAccounts, setTeacherAccounts] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [studentContext, setStudentContext] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [unitPlans, setUnitPlans] = useState(null);

    const user = useContext(UserContext);

    const fetchStandardSets = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getStandardSets', user.userAttributes['custom:school'])
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getRosters', user.userAttributes['custom:school'])
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getKnowShow',  user.userAttributes['custom:school'])
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/coordinatorplanning/getAssessmentItems',  user.userAttributes['custom:school'])
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchTeacherAccounts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/coordinatorplanning/getTeacherAccounts',  user.userAttributes['custom:school'])
                    .then(async r => {
                        setTeacherAccounts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentContext = async (preps) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getStudentContext`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(preps))})
                    .then(async r => {
                        setStudentContext(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchGroupData = async (user) => {
        try {
            getWithAuth(`/data/getStudentData`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                .then(async r => {
                    let data = JSON.parse(r)
                    setGroupData(data.currentData)
                })
        } catch (error) {
            console.error('Error fetching standards data:', error)
        }

    }

    const getUnitPlans = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getUnitPlans`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setUnitPlans(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }


    useEffect(() => {
        fetchTeacherAccounts(user);
        fetchStandardSets();
        fetchRosters();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
    }, [user.userContext])

    // Get teacher account information and pass that info into logistics?

    const handleChangeUser = (e) => {
        let newUserObj = teacherAccounts.find(account => account._id.$oid === e.target.value);
        let stateObj = {userAttributes: {'custom:school': user.userAttributes['custom:school']}, userContext: newUserObj};
        setSelectedUser(stateObj)
    }

    useEffect(() => {
        if (selectedUser) {
            getUnitPlans(selectedUser);
            fetchGroupData(selectedUser);
            fetchStudentContext(selectedUser.userContext.preps)
        }
    }, [selectedUser])

    return (
        <>
        <CoordinatorNavBar />
            {(teacherAccounts) ?
        <>
            <div
                className="flex items-center bg-columbia_blue-300 justify-between p-4 border-b-2 border-columbia_blue-800">
                <h2 className="text-xl font-bold text-columbia_blue-800">Coordinator Dashboard Client</h2>
                {(selectedUser) && <p>Active Dashboard: {selectedUser.userContext.firstName} {selectedUser.userContext.lastName}</p>}
                <select
                    className="rounded p-4 bg-columbia_blue-800 text-dutch_white-300 font-bold"
                    onChange={handleChangeUser}
                    defaultValue=""
                >
                    <option disabled value="">
                        Select a Teacher Account
                    </option>
                    {teacherAccounts
                        .sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()))
                        .map(account => (
                            <option key={account.email} value={account._id.$oid}>
                                {account.email}
                            </option>
                        ))}
                </select>
            </div>
            <div className={'p-2 m-2 border-2 border-columbia_blue-800'}>
            {(selectedUser) &&
                <Logistics user={selectedUser} standardSets={(standardSets) && standardSets.filter(standardSet => selectedUser.userContext.preps.includes(standardSet.prepTitle))} setStandardSets={setStandardSets}
                           rosters={rosters.filter(roster => roster.instructorId === selectedUser.userContext._id.$oid)} setRosters={setRosters} knowShowCharts={knowShowCharts}
                           setKnowShowCharts={setKnowShowCharts} studentContext={studentContext} setStudentContext={setStudentContext} assessmentItems={assessmentItems}
                           setAssessmentItems={setAssessmentItems} unitPlans={unitPlans} setUnitPlans={setUnitPlans} groupData={groupData} setGroupData={setGroupData} fetchRosters={fetchRosters} getUnitPlans={getUnitPlans}/>
            }</div>
        </>
            : <Loader message={'Fetching Teacher Accounts...'} />}
            </>
    )

}

export default DesignFetchLayerCoord;