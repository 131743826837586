import React, { useEffect, useRef, useState } from 'react';
import TraditionalMCInput from "./traditionalMCInput";
import ImageMCInput from "./imageMCInput";
import MatchingInput from "./matchingInput";
import SortingInput from "./sortingInput";

function CreateItemModal({ user, createItemModal, setCreateItemModal, assessmentItems, setAssessmentItems, resetFunction }) {
    const [questionType, setQuestionType] = useState('Traditional MC');
    const [isLoading, setIsLoading] = useState(false);

    const onClose = () => {
        if (window.confirm("Are you sure? Closing this window will remove all question content.")) {
            setCreateItemModal(null);
        }
    };

    const handleClose = (e) => {
        if (e.target.id === "wrapper") onClose();
    };

    const createModalRef = useRef(null);

    useEffect(() => {
        const modalElement = createModalRef.current;

        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        // Focus the first focusable element when the modal opens
        focusableElements[1].focus()
        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                onClose();
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            modalElement.removeEventListener('keydown', trapFocus);
        };
    }, [setCreateItemModal, createItemModal, questionType, setQuestionType]);


    return (
        <div
            className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-30"}
            id="wrapper"
            onClick={handleClose}
        >
            <div
                className={"relative bg-platinum-500 m-4 rounded p-2 w-[80%] max-h-[95%]"}
                ref={createModalRef}
            >
                <button
                    onClick={onClose}
                    className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={'p-2 bg-columbia_blue-800 flex flex-col max-h-full'}>
                    <div className={'flex gap-4'}>
                        {(createItemModal.questionType === "Traditional MC") ?
                            <div className={'py-2 px-4 rounded-t bg-columbia_blue-500 select-none'}>
                                <p>Traditional MC</p>
                            </div>
                            :
                            <button className={'py-2 px-4 rounded-t bg-columbia_blue-500 hover:bg-columbia_blue-600 unselected-tab select-none'} onClick={() => setCreateItemModal(prev => ({ ...prev, questionType: 'Traditional MC' }))}>
                                <p>Traditional MC</p>
                            </button>}
                        {(createItemModal.questionType === "Image MC") ?
                            <div className={'py-2 px-4 rounded-t bg-burnt_sienna-300 select-none'}>
                                <p>Image MC</p>
                            </div>
                            :
                            <button className={'py-2 px-4 rounded-t bg-burnt_sienna-300 hover:bg-burnt_sienna-400 select-none unselected-tab'} onClick={() => setCreateItemModal(prev => ({ ...prev, questionType: 'Image MC' }))}>
                                <p>Image MC</p>
                            </button>}
                        {(createItemModal.questionType === "Matching") ?
                            <div className={'py-2 px-4 rounded-t bg-dutch_white-500 select-none'}>
                                <p>Matching</p>
                            </div>
                            :
                            <button className={'py-2 px-4 rounded-t bg-dutch_white-500 hover:bg-dutch_white-600 select-none unselected-tab'} onClick={() => setCreateItemModal(prev => ({ ...prev, questionType: 'Matching' }))}>
                                <p>Matching</p>
                            </button>}
                        {(createItemModal.questionType === "Sorting") ?
                            <div className={'py-2 px-4 rounded-t bg-paynes_gray-300 select-none'}>
                                <p>Sorting</p>
                            </div>
                            :
                            <button className={'py-2 px-4 rounded-t bg-paynes_gray-300 hover:bg-paynes_gray-400 select-none unselected-tab'} onClick={() => setCreateItemModal(prev => ({ ...prev, questionType: 'Sorting' }))}>
                                <p>Sorting</p>
                            </button>}
                    </div>
                    {(createItemModal.questionType === "Traditional MC") ?
                        <TraditionalMCInput user={user} createItemModal={createItemModal} setCreateItemModal={setCreateItemModal} setIsLoading={setIsLoading} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems}/>
                        : (createItemModal.questionType === "Image MC") ? <ImageMCInput user={user} createItemModal={createItemModal} setCreateItemModal={setCreateItemModal} setIsLoading={setIsLoading} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems}/>
                            : (createItemModal.questionType === "Matching") ? <MatchingInput user={user} createItemModal={createItemModal} setCreateItemModal={setCreateItemModal} setIsLoading={setIsLoading} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems}/>
                                : (createItemModal.questionType === "Sorting") ? <SortingInput user={user} createItemModal={createItemModal} setCreateItemModal={setCreateItemModal} setIsLoading={setIsLoading} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems}/>
                                    : <></>}
                </div>
            </div>
            {(isLoading) && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </div>
    );
}

export default CreateItemModal;