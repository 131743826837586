import React from 'react';
import {useNavigate} from "react-router-dom";

function Footer(props) {

    let navigate = useNavigate()

    const links = [
        {display: 'Acknowledgments', destination: '/acknowledgments'},
        {display: 'Terms and Conditions', destination: '/terms'},
        {display: 'Privacy Policy', destination: '/privacy'},
        {display: 'Contact', destination: '/contact'}

    ]

    return (
        <div className={'flex text-xs py-2 text-dutch_white-300 shadow-inner bg-columbia_blue-800 select-none'}>
            <div className={'grow'}/>
            <a target={'_blank'} className={'cursor-pointer hover:text-dutch_white-100'} href={'https://www.brainmeter.info'}>
                About
            </a>
            <p className={'mx-2'}>-</p>
            {links.map((section, index) => <>
                <p className={'cursor-pointer hover:text-dutch_white-100'}
                   tabIndex={0}
                   onClick={() => navigate(section.destination)}
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           navigate(section.destination);
                       }
                   }}>
                    {section.display}
                </p>
                {(index < links.length - 1 && <p className={'mx-2'}>-</p>)}
            </>)}
            <div className={'grow'}/>
        </div>
    );
}

export default Footer;