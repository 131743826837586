import React from 'react';
import {useNavigate} from "react-router-dom";
import {signOut} from "aws-amplify/auth";

function TeacherHome() {

    const navigate = useNavigate()
    const logo = require('../assets/transparentLogo.png')
    const design = require('../assets/Design.png')
    const dashboard = require('../assets/Dashboard.png')
    const data = require('../assets/Data.png')


    return (
        <>
            <div className={'flex justify-between bg-dutch_white-400 py-4 shadow-xl'}>
                <div className={"px-4 flex"}>
                    <img className={'h-[120px] w-[120px] my-auto'}
                         src={String(logo)}
                         alt={'Brainmeter'}/>
                    <p className={'text-6xl text-columbia_blue-800 my-auto ml-8'}>Brainmeter</p>
                </div>
                <button
                    className={'bg-columbia_blue-800 text-dutch_white-200 rounded p-2 mr-6 h-fit hover:bg-columbia_blue-700'}
                    onClick={signOut}>Sign Out
                </button>
            </div>
            <div className={'flex w-full'}>
                <div className={'grow'}/>

                <button onClick={() => {
                    navigate('/design')
                }}
                        className={'w-[30%] m-4 bg-dutch_white-100 hover:bg-dutch_white-300 hover:shadow-lg rounded shadow'}>
                    <div className={'flex flex-col select-none h-full pt-4'}>
                        <p className={'text-5xl text-columbia_blue-800 my-auto mb-4 font-bold'}>Design</p>

                        <img
                            src={String(design)}
                            alt={'Clipboard and writing pencil clipart'}
                            className={'w-[50%] mx-auto'}
                        />
                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                            <li className={'mb-4'}>Break down your standards into teachable standard
                                elements
                            </li>
                            <li className={'mb-4'}>Draft your assessment questions</li>
                            <li className={'mb-4'}>Collaborate with the other teachers that share your
                                preps
                            </li>
                        </ul>
                        <div className={'grow'}/>
                    </div>
                </button>
                <button onClick={() => {
                    navigate('/dashboard')
                }}
                        className={'w-[30%] m-4 bg-burnt_sienna-100 hover:bg-burnt_sienna-200 hover:shadow-lg rounded shadow'}>
                    <div className={'flex flex-col select-none h-full pt-4'}>

                        <p className={'text-5xl text-burnt_sienna-600 my-auto mb-4 font-bold'}>Dashboard</p>

                        <img
                            src={String(dashboard)}
                            alt={'Calendar of events clip-art'}
                            className={'w-[50%] mx-auto'}
                        />
                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                            <li className={'mb-4'}>Arrange your standard elements into unit plans</li>
                            <li className={'mb-4'}>Create and edit rosters for your classes</li>
                            <li className={'mb-4'}>Create data driven seating charts to maximize student
                                collaboration opportunities
                            </li>
                            <li className={'mb-4'}>Review individual level student data for
                                participation and understanding of specific standard elements
                            </li>
                        </ul>
                        <div className={'grow'}/>
                    </div>

                </button>
                <button onClick={() => {
                    navigate('/data')
                }}
                        className={'w-[30%] m-4 bg-columbia_blue-100 hover:bg-columbia_blue-300 hover:shadow-lg rounded shadow'}>
                    <div className={'flex flex-col select-none h-full pt-4'}>
                        <p className={'text-5xl text-columbia_blue-800 my-auto font-bold'}>Data</p>

                        <img src={String(data)}
                             alt={'Various charts and graphs clip-art'} className={'w-[50%] mx-auto'}
                        />
                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                            <li className={'mb-4'}>Review balcony level data</li>
                            <li className={'mb-4'}>Compare student understanding and instructional
                                quality across multiple rosters and preps
                            </li>
                            <li className={'mb-4'}>Identify groups of students with shared
                                misconceptions
                            </li>
                        </ul>
                        <div className={'grow'}/>
                    </div>

                </button>
                <div className={'grow'}/>


            </div>
        </>);
}

export default TeacherHome;