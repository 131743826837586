import React, {useContext, useEffect, useState} from 'react';
import getWithAuth from "../../context/getWithAuth";
import userContext from "../../context/userContext";
import Loader from "../../components/loader";
import ToSModal from "../../components/tosModal";
import {useNavigate} from "react-router-dom";
import GradeLevelSummaryBlock from "./gradeLevelSummaryBlock";

function BuildingSummary(props) {
    const [schoolSummary, setSchoolSummary] = useState(null);

    const user = useContext(userContext)
    let navigate = useNavigate()

    const getSchoolSummary = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/building/getSchoolSummary', user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setSchoolSummary(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        getSchoolSummary()
    }, [user.userContext])

    if (!user.userContext) {
        return <Loader message={'Fetching User Data'}/>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role !== 'coordinator') {
        navigate('/')
    } else if (!schoolSummary) {
        const loadingItems = [
            !schoolSummary && "Learning Data",
        ].filter(Boolean); // Filter out false/null values

        const fetchingText = loadingItems.length > 1
            ? `Fetching: ${loadingItems.slice(0, -1).join(", ")} and ${loadingItems.slice(-1)}`
            : `Fetching: ${loadingItems[0] || ""}`;
        return <Loader message={fetchingText}/>
    } else {
        return (
            <>
            <div className={'flex bg-columbia_blue-900 mx-1'}>
                {schoolSummary.sort((a, b) => Number(a.grade) - Number(b.grade)).map(item => <GradeLevelSummaryBlock data={item}/>)}
            </div>
        </>
        );
    }
}

export default BuildingSummary;