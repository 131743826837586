import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TipTap from "./tipTap";
import userContext from "../context/userContext";
import postWithAuth from "../context/postWithAuth";
import Loader from "./loader";


function ContactPage() {
    const [messageType, setMessageType] = useState(null);
    const [messageContent, setMessageContent] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const user = useContext(userContext)
    const navigate = useNavigate()

    const handleMessageTypeChange = (e) => {
        setMessageType(e.target.value)
    }

    const handleMessageSubmit = () => {
        setIsLoading(true);
        let submission = {messageType: messageType, school: user.userContext.school, messageContent: messageContent, email: user.userContext.email, senderName: `${user.userContext.firstName} ${user.userContext.lastName}`}
        let url = new URL(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/messages/postContactForm`)
        url.searchParams.append('school', user.userAttributes["custom:school"]);
        postWithAuth('/messages/postContactForm', user.userAttributes["custom:school"], {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: submission
        })
            .then(async response => {
                if (response.ok) {
                    window.alert('Your message has been successfully submitted. We will reach out in response via email as soon as we are able. This window will now redirect to the Brainmeter homepage.');
                    navigate('/')
                } else {
                    console.log(response.error)
                    setIsLoading(false)
                }
            })
    }

    return (
        <div className={'flex flex-col min-h-screen bg-columbia_blue-800'}>
            <div className={'flex justify-between bg-columbia_blue-800 py-4'}>
                <div className={'grow'}></div>
                <button className={"px-4"} onClick={() => navigate('/')} title={'Home Button'}>
                    <img className={'h-[70px] cursor-pointer mx-auto pb-2'}
                         src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                         alt={'Brainmeter'}/>
                </button>
                <div className={'grow'}></div>
            </div>
            <div className={'bg-columbia_blue-800 pb-8'}>
                <div
                    className={'text-left max-w-[70%] bg-dutch_white-200 mx-auto text-columbia_blue-900 py-4 px-8 select-none rounded'}>
                    <p>Using the form below, select the type of message you would like to send and type your message.
                        Your email and information will be recorded along with your message--please provide as much
                        detail as possible and we will reach out as soon as we are able.</p>

                    <div className={'flex gap-4 p-2'}>
                        <select onChange={handleMessageTypeChange} className={'p-1 bg-dutch_white-100'} defaultValue="">
                            <option disabled value="">
                                Select a message type
                            </option>
                            <option>Issue Report</option>
                            <option>Feature Request</option>
                            <option>Technical Support</option>
                        </select>
                    </div>
                    <TipTap setHtmlOutput={setMessageContent}/>
                    <div className={'w-full flex mt-4'}>
                        <div className={'grow'}/>
                        {(messageType && messageContent) ?
                            <button
                                className={'mx-auto bg-columbia_blue-700 p-2 rounded shadow text-white'} onClick={handleMessageSubmit}>Submit</button> :
                            <button
                                className={'mx-auto bg-platinum-400 p-2 rounded shadow cursor-default text-platinum-700'}>Submit</button>}
                        <div className={'grow'}/>
                    </div>

                </div>
            </div>
            {(isLoading) && <Loader message={'Submitting Contact Form'} />}
        </div>
    )
        ;
}

export default ContactPage;