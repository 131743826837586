import React, {useEffect, useRef, useState} from 'react';
import postWithAuth from "../../context/postWithAuth";

function SupplementalStandardModal({setSupplementalStandardModal, standardSet, school, resetFunction}) {
    const [isLoading, setIsLoading] = useState(null);
    const [title, setTitle] = useState(null);
    const [supportingText, setSupportingText] = useState(null);
    const [text, setText] = useState(null);
    const [code, setCode] = useState(null);
    const [standardType, setStandardType] = useState('Supplemental');
    const [activePreps, setActivePreps] = useState([]);

    const modalRef = useRef(null);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (!modalElement) return;

        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        // Focus the first focusable element when the modal opens
        if (firstFocusableElement) firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                onClose();
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            modalElement.removeEventListener('keydown', trapFocus);
        };
    }, [setSupplementalStandardModal]);

    const onClose = () => {
        if (window.confirm('Are you sure? All progress will be lost')) {
            setSupplementalStandardModal(null)
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleTypeChange = (e) => {
        setStandardType(e.target.value)
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value)
    }

    const handleTextChange = (e) => {
        setText(e.target.value)
    }

    const handleSupportingTextChange = (e) => {
        setSupportingText(e.target.value)
    }

    const standardTypes = ['Supplemental', 'Remedial', 'Prerequisite', 'Assessment Readiness']

    const postCreateSupplementalStandard = async () => {
        if (!title || !text || !code) {
            window.alert('Your standard must have a title, a code, and description text. Supporting text is optional.')
        } else if (window.confirm('Are you sure? Only coordinators can remove standards once added.')) {
            let body = {
                standardType: standardType,
                title: title,
                code: code,
                text: text,
                supportingText: supportingText,
                standardSet: standardSet,
                standardSetId: standardSet._id.$oid
            }
            setIsLoading(true);
            postWithAuth('/planning/postCreateSupplementalStandard', school, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body})
                .then(async (response) => {
                    if (response.ok) {
                        window.alert('Standard added to database')
                        await resetFunction();
                        setIsLoading(false);
                        setSupplementalStandardModal(false);
                    } else {
                        if (response.status === 499) {
                            window.alert('This standard code overlaps with an existing code in this standard set. Please change the standard code and try again.')
                            setIsLoading(false)
                        } else {
                            window.alert('An error has occurred, please try again later.')
                        }
                    }
                })
        } else {
            return null;
        }
    }


    return (
        <>
            <div>
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-20"} id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded h-max-[90%]"} ref={modalRef}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                            <div className={'flex-col'}>
                                <div className={'flex gap-2'}>
                                    <div className={'mx-auto flex gap-4 h-fit'}>
                                        <p className={'text-dutch_white-300 font-bold text-xl my-auto '}>Standard Type:</p>
                                        <select onChange={handleTypeChange} className={'bg-columbia_blue-300 my-auto p-2 rounded'}>
                                            {standardTypes.map(standard => (<option value={standard} key={standard} >{standard}</option>))}
                                        </select>

                                    </div>

                                </div>
                                <div className={'flex gap-2'}>
                                    <div className={'mx-auto flex flex-col'}>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Standard Title:</p>

                                        <textarea onChange={handleTitleChange}
                                                  className={'rounded grow p-4 w-[500px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}
                                                  placeholder={'Choose a title for your standard...'}
                                        />
                                    </div>
                                    <div className={'mx-auto flex flex-col'}>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Desired Standard Code:</p>
                                        <p className={'text-xs text-dutch_white-300 mb-1'}>The code must be unique within this standard set.</p>
                                        <textarea onChange={handleCodeChange}
                                                  className={'rounded grow p-4 w-[500px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}
                                                  placeholder={'Choose a unique code for your standard...'}
                                        />
                                    </div>

                                </div>
                                <div className={'flex gap-2 mx-auto mb-10'}>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Standard Text:</p>
                                        <textarea onChange={handleTextChange}
                                                  className={'rounded p-2 w-[500px] h-[200px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}
                                                  placeholder={'Describe your new standard...'}
                                        />
                                    </div>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Supporting
                                            Text:</p>
                                        <textarea onChange={handleSupportingTextChange}
                                                  className={'rounded p-2 h-[200px] w-[500px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}
                                                  placeholder={'[Optional] Provide additional detail for your standard...'}
                                        />
                                    </div>
                                </div>

                            </div>

                            <button onClick={postCreateSupplementalStandard}
                                    className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-burnt_sienna text-white font-bold px-10 text-xl hover:bg-burnt_sienna-600">
                                <p>Create New Standard</p>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </>
    );
}

export default SupplementalStandardModal;