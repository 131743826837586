import React, {useEffect, useRef, useState} from 'react';
import InteractiveSearch from "../../components/interactiveSearch";
import putWithAuth from "../../context/putWithAuth";

function EditRosterModal({user, studentContext, rosters, setRosters, editRosterModal, setEditRosterModal}) {
    const [isLoading, setIsLoading] = useState(false);
    const [studentsToRemove, setStudentsToRemove] = useState([]);
    const [studentsToAdd, setStudentsToAdd] = useState([]);
    const [studentFilterText, setStudentFilterText] = useState(null);

    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState(formatDate(new Date(editRosterModal.startDate.$date)));
    const [endDate, setEndDate] = useState(formatDate(new Date(editRosterModal.endDate.$date)));
    let studentArr = studentContext.filter(student => editRosterModal.students.includes(student._id.$oid)).sort((a, b) => a.lastName.localeCompare(b.lastName, undefined, { sensitivity: 'base' }));
    const onClose = () => {
        setEditRosterModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const submitRosterChange = () => {
        if (studentsToRemove.length === 0 && studentsToAdd.length === 0 && startDate === formatDate(new Date(editRosterModal.startDate.$date)) && endDate === formatDate(new Date(editRosterModal.endDate.$date)) ) {
            window.alert('Please modify your roster before submitting changes.')
        } else {
            if (!window.confirm('Please confirm that these roster changes are accurate.')) {
                return null;
            } else {
                setIsLoading(true);
                putWithAuth('/logistics/putRosterUpdate', user.userAttributes['custom:school'],{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        rosterId: editRosterModal._id.$oid,
                        addedStudents: studentsToAdd,
                        removedStudents: studentsToRemove,
                        startDate: startDate,
                        endDate: endDate,
                        prep: editRosterModal.prep
                    }
                })
                    .then(async (response) => {
                        if (response.ok) {
                            let currentRosters = [...rosters];
                            let updatedRosters = currentRosters.filter(item => (item.title !== editRosterModal.title))
                            let result = await response.json();
                            let newRoster = result.roster
                            updatedRosters.push(newRoster)
                            await setRosters(updatedRosters);
                            window.alert(result.message)
                            setIsLoading(false);
                            setEditRosterModal(null);
                        } else {
                            throw new Error('Failed to update Roster');
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                        setIsLoading(false);
                    })
            }}}


    const handleActiveStudentClick = (e) => {
        let currentStudentsToRemove = [...studentsToRemove];
        currentStudentsToRemove.push(e.target.id);
        setStudentsToRemove(currentStudentsToRemove);
    }

    const handleUnrosteredStudentClick = (e) => {
        let currentStudentsToAdd = [...studentsToAdd];
        currentStudentsToAdd.push(e.target.id);
        setStudentsToAdd(currentStudentsToAdd)
    }

    const handleAddStudentClick = (e) => {
        setStudentsToAdd(studentsToAdd.filter(item => item !== e.target.id))
    }

    const handleRemoveStudentClick = (e) => {
        setStudentsToRemove(studentsToRemove.filter(item => item !== e.target.id));
    }

    const handleFilterChange = (e) => {
        setStudentFilterText(e.target.value);
    }

    const modalRef = useRef(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        focusableElements[1].focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setEditRosterModal]);

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper" onClick={handleClose}>
                <div className={"relative bg-dutch_white-400 p-2 m-4 rounded w-[95%] h-[95%]"} ref={modalRef}>
                    <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={'p-2 bg-dutch_white-200 flex h-full'}>
                        <div className={'bg-platinum-900 rounded-lg p-1 w-1/5 max-h-full'}>
                            <InteractiveSearch placeholder={'Search Name or Grade'} handleChange={handleFilterChange}/>
                            <div className={'bg-platinum-900 p-4 flex flex-col overflow-y-auto gap-1 max-h-[90%] w-full rounded text-sm'}>

                                {(studentFilterText) && <>
                                        {studentContext.filter(item => !editRosterModal.students.includes(item._id.$oid) && item.active && (!rosters.filter(roster => new Date(roster.startDate.$date) <= new Date() && new Date(roster.endDate.$date) >= new Date()).filter(roster => roster.prep === editRosterModal.prep).flatMap(item => item.students).includes(item._id.$oid)) && (!studentsToAdd.includes(item._id.$oid))).filter(item => item.firstName.toLowerCase().includes(studentFilterText.toLowerCase()) || item.lastName.toLowerCase().includes(studentFilterText.toLowerCase()) || item.grade.includes(studentFilterText)).sort((a, b) => a.lastName.localeCompare(b.lastName)).map(student =>
                                            <button id={student._id.$oid} className={'p-2 bg-columbia_blue rounded h-fit select-none hover:bg-columbia_blue-600 cursor-pointer'} onClick={handleUnrosteredStudentClick}>
                                                <p id={student._id.$oid}>{student.firstName} {student.lastName}</p>
                                            </button>)}
                                    </>
                                }
                            </div>
                        </div>
                        <div className={'flex flex-col w-4/5 max-h-full overflow-y-auto'}>
                            <div className={'grid grid-cols-2 mt-2'}>
                                <div className={'flex mx-auto gap-4'}>
                                    <p className={'select-none my-auto font-bold'}>Start Date:</p>
                                    <input className={'bg-dutch_white-100 rounded p-2'} type={"date"} value={startDate}
                                           onChange={(e) => setStartDate(e.target.value)}/>

                                </div>
                                <div className={'flex mx-auto gap-4'}>
                                    <p className={'select-none my-auto font-bold'}>End Date:</p>
                                    <input className={'bg-dutch_white-100 rounded p-2'} type={"date"} value={endDate}
                                           onChange={(e) => setEndDate(e.target.value)}/>

                                </div>
                            </div>
                            <p className={'text-columbia_blue-800 font-bold text-2xl select-none'}>{editRosterModal.title} - {editRosterModal.students.length + studentsToAdd.length - studentsToRemove.length} students</p>
                            <div className={'flex flex-wrap gap-2 p-2 ml-2 border-b-2 border-columbia_blue h-fit'}>
                                {studentArr.filter(item => !studentsToRemove.includes(item._id.$oid)).map(item =>
                                    <button id={item._id.$oid}
                                            className={'p-2 grow bg-columbia_blue-400 shadow border-[1px] border-columbia_blue-800 rounded h-fit select-none hover:bg-columbia_blue-500 cursor-pointer text-columbia_blue-900'}
                                            onClick={handleActiveStudentClick}>
                                        <p id={item._id.$oid}>{item.firstName} {item.lastName}</p>
                                    </button>
                                )}
                                <div className={'grow'}></div>
                            </div>
                            <div className={'grid grid-cols-2 grow'}>
                                <div>
                                    <p className={'text-columbia_blue-800 font-bold text-2xl select-none'}>Students to
                                        Add:</p>

                                    <div className={'flex flex-wrap gap-2 p-2 mx-auto'}>
                                        {studentContext.sort((a, b) => a.lastName.localeCompare(b.lastName)).filter(item => studentsToAdd.includes(item._id.$oid)).map(item =>
                                            <button id={item._id.$oid}
                                                    className={'p-2 grow bg-columbia_blue-400 shadow border-[1px] border-columbia_blue-800 rounded h-fit select-none hover:bg-columbia_blue-500 cursor-pointer text-columbia_blue-900'}
                                                    onClick={handleAddStudentClick}>
                                                <p id={item._id.$oid}>{item.firstName} {item.lastName}</p>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <p className={'text-columbia_blue-800 font-bold text-2xl select-none'}>Students to
                                        Remove:</p>

                                    <div className={'flex flex-wrap gap-2 p-2'}>
                                        {studentArr.sort((a, b) => a.lastName.localeCompare(b.lastName)).filter(item => studentsToRemove.includes(item._id.$oid)).map(item =>
                                            <button id={item._id.$oid}
                                                    className={'p-2 grow bg-burnt_sienna-200 rounded shadow border-[1px] border-burnt_sienna-800 h-fit select-none hover:bg-burnt_sienna-300 cursor-pointer text-columbia_blue-900'}
                                                    onClick={handleRemoveStudentClick}>
                                                <p id={item._id.$oid}>{item.firstName} {item.lastName}</p>
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <button onClick={submitRosterChange}
                            className="absolute self-center rounded -bottom-5 align-middle m-2 p-2 bg-columbia_blue-700 text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                        <p>Save Roster Changes</p>
                    </button>
                    {(isLoading) &&
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                            {/* Loader */}
                            <div
                                className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-columbia_blue-700"></div>
                        </div>}
                </div>
        </div>
    );
}

export default EditRosterModal;