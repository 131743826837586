import React from 'react';
import DOMPurify from "dompurify";

function RecallHoverItemTooltip({data}) {

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };
    return (
        <div className={"bg-columbia_blue-500 p-1 rounded min-w-[300px] max-w-[400px] text-center"}>
            <div className={'bg-dutch_white-300 max-w-[400px] p-1 rounded h-fit mx-auto'}>
                <div className={'text-dutch_white-900 h-fit'} dangerouslySetInnerHTML={renderHtml(data.item.prompt.content)}/>
            </div>
            <div className={'grid grid-cols-3 gap-2 w-full mt-1 text-xs'}>
                {(data.names.length > 0) ? data.names.map(item =>
                        <div className={'font-bold p-1 bg-burnt_sienna-400 rounded text-dutch_white-100 h-full flex flex-col'}>
                            <div className={'grow'}/>
                                <p className={'my-auto'}>{item}</p>
                            <div className={'grow'}/>
                        </div>)
                    : <div className={'col-start-2 font-bold p-1 bg-columbia_blue-700 rounded text-dutch_white-100'}>
                        <p>No misses!</p>
                    </div>}
            </div>

        </div>);
}

export default RecallHoverItemTooltip;