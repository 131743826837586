import React, {useState} from 'react';
import BuildingSummary from "../building/buildingSummary";
import AccountManagement from "../accountManagement/accountManagement";

function Administration() {
    const [currentPage, setCurrentPage] = useState('building')

    return (
        <div>
        <div className={'flex gap-2 mx-1'}>
            {(currentPage !== 'building') ?
            <button className={'bg-columbia_blue-300 text-dutch_white-900 rounded-t text-xl font-bold p-4 hover:bg-columbia_blue-400 cursor-pointer'} onClick={() => setCurrentPage('building')}>School Overview</button>
                : <div className={'unselected-tab bg-columbia_blue-800 text-dutch_white-200 text-xl font-bold p-4 rounded-t select-none'}>School Overview</div>
            }
            {(currentPage !== 'accounts') ?
                <button className={'bg-columbia_blue-300 text-dutch_white-900 rounded-t text-xl font-bold p-4 hover:bg-columbia_blue-400 cursor-pointer'} onClick={() => setCurrentPage('accounts')}>Account Management</button>
                : <div className={'unselected-tab bg-columbia_blue-800 text-dutch_white-200 text-xl font-bold p-4 rounded-t select-none'}>Account Management</div>}
        </div>
            {(currentPage === 'building') && <BuildingSummary />}
            {(currentPage === 'accounts') && <AccountManagement />}
        </div>
    );
}

export default Administration;