import React, {useRef, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import postWithAuth from "../../context/postWithAuth";
import TipTap from "../../components/tipTap";

function TraditionalMcInput({user, createItemModal, setCreateItemModal, assessmentItems, setAssessmentItems, setIsLoading}) {
    const [questionTextContent, setQuestionTextContent] = useState({prompt: null, correct: null, distractor1: null, distractor2: null, distractor3: null})
    const hiddenFileInput = React.useRef(null);
    let [stimulusImage, setStimulusImage] = useState(null)
    let [pageImage, setPageImage] = useState(null)
    let [editorQuestionHtml, setEditorQuestionHtml] = useState(null)
    let [editorCorrectHtml, setEditorCorrectHtml] = useState(null)
    let [editorDistractor1Html, setEditorDistractor1Html] = useState(null)
    let [editorDistractor2Html, setEditorDistractor2Html] = useState(null)
    let [editorDistractor3Html, setEditorDistractor3Html] = useState(null)

    const publishTraditionalMCItem = () => {
        let questionTextContent = {prompt: editorQuestionHtml, correct: editorCorrectHtml, distractor1: editorDistractor1Html, distractor2: editorDistractor2Html, distractor3: editorDistractor3Html}
        if (Object.keys(questionTextContent).map(item => questionTextContent[item]).includes(null)) {
            window.alert("You must include a prompt, a correct answer, and all three distractors to submit.")
            return null;
        } else if (!window.confirm("Are you sure? Submitting now will store all question content permanently in the database.")) {
            return null;
        } else if (stimulusImage) {
            setIsLoading(true);
            let data = new FormData();
            data.set( "UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY );
            data.set("UPLOADCARE_STORE", 1)
            data.set("filename", stimulusImage.name)
            data.append('file', stimulusImage);

            fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: data,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to upload image.');
                    }
                })
                .then((data) => {
                    const imageURL = `https://ucarecdn.com/${data.file}/`; // Extract the URL from the response data
                    // Make another request with the imageURL and questionTextContent
                    postWithAuth('/planning/postTraditionalMCItem',user.userAttributes['custom:school'], {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: {
                            knowShowId: createItemModal._id.$oid,
                            knowShowText: createItemModal.text,
                            standard: createItemModal.standard,
                            prep: createItemModal.prep,
                            school: user.userContext.school,
                            questionTextContent: {prompt: editorQuestionHtml, correct: editorCorrectHtml, distractor1: editorDistractor1Html, distractor2: editorDistractor2Html, distractor3: editorDistractor3Html},
                            imageURL: imageURL,
                        },
                    })
                        .then(async (response) => {
                            if (!response.ok) {
                                throw new Error('Failed to make the second request.');
                            } else {
                                return response.json();
                            }
                        })
                        .then(async data => {
                            await setAssessmentItems(prev => [...prev, data]);
                            setIsLoading(false);
                            setCreateItemModal(null);
                        })
                        .catch((error) => {
                            console.error('An error occurred during the second request:', error);
                        });
                })
                .catch((error) => {
                    console.error('An error occurred during upload:', error);

                });
        } else {
            setIsLoading(true);
            let body = {
                knowShowId: createItemModal._id.$oid,
                knowShowText: createItemModal.text,
                standard: createItemModal.standard,
                questionTextContent: {prompt: editorQuestionHtml, correct: editorCorrectHtml, distractor1: editorDistractor1Html, distractor2: editorDistractor2Html, distractor3: editorDistractor3Html},
                school: user.userContext.school,
                prep: createItemModal.prep
            };
            postWithAuth('/planning/postTraditionalMCItem', user.userAttributes['custom:school'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            })
                .then(async (response) => {
                    if (!response.ok) {
                        throw new Error('Failed to make the upload.');
                    } else {
                        return response.json();
                    }
                })
                .then(async data => {
                    await setAssessmentItems(prev => [...prev, data]);
                    setIsLoading(false);
                    setCreateItemModal(null);
                })
                .catch((error) => {
                    console.error('An error occurred during the question upload:', error);
                });
        }

    }

    const handlePaste = (e) => {
        // Prevent the default paste behavior
        e.preventDefault();

        // Get the pasted text
        const text = e.clipboardData.getData('text/plain');

        // Insert the text into the editor
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(text);
    };

    const quillRef = React.createRef();

    let handleImageChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            setStimulusImage(file)
            setPageImage(URL.createObjectURL(file))
        }
    }

    const handleClearImage = () => {
        setPageImage(null);
        setStimulusImage(null);
    };

    return (
        <>
            <div className={"bg-columbia_blue p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto"}>
                <div className={"bg-dutch_white-300 text-lg p-2 rounded shadow"}>{createItemModal.text}</div>
                <div className={"grid grid-cols-7 my-2"}>
                    <div className={"col-span-3 h-full flex flex-col mx-2"}>
                        {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center">*/}
                        {/*</button>*/}
                        {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center animate-pulse hover:animate-none">*/}
                        {/*    <span className="text-xl">AI</span>*/}
                        {/*</button>*/}
                        <div className={'editor-container'}>
                            {/*<ReactQuill ref={quillRef} onPaste={handlePaste} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ script: 'sub' }, { script: 'super' }]] }} id={"prompt"} onChange={handleEditorQuestionChange} placeholder={"Question:"} className={'bg-dutch_white-100'}/>*/}

                            <TipTap setHtmlOutput={setEditorQuestionHtml} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Question...'} />
                            {/*<Editor*/}
                            {/*    id={'prompt'}*/}
                            {/*    ref={newQuillRef}*/}
                            {/*    readOnly={false}*/}
                            {/*    placeholder={'Question:'}*/}
                            {/*    backgroundColor={'#faf7ef'}*/}
                            {/*    onTextChange={handleEditorQuestionChange}*/}
                            {/*/>*/}
                        </div>
                        <div className={"grid grid-cols-2 gap-2 mt-4"}>
                            <input type={"file"} ref={hiddenFileInput} accept="image/jpeg, image/png, image/jpg"
                                   className={"hidden"} onChange={handleImageChange}/>
                            {(pageImage) ? <button
                                className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}
                                onClick={() => hiddenFileInput.current.click()}>Change Image</button> : <button
                                className={"bg-columbia_blue-700 mx-auto col-span-2 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}
                                onClick={() => hiddenFileInput.current.click()}>Add an Image</button>}
                            {(pageImage) && <button onClick={handleClearImage}
                                                    className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove
                                Image</button>}
                            {(pageImage) && <div className={"mb-2 select-none col-span-2"}>
                                <div className={'flex flex-col mx-auto'}>
                                    <div
                                        className={'p-2 bg-dutch_white-300 border-2 border-white w-[50%] mx-auto rounded'}>
                                        <p className={'font-bold'}>Image Preview</p><img
                                        className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={pageImage}/>
                                    </div>
                                </div>
                            </div>}
                            <p className={'col-span-2 text-sm bg-burnt_sienna-300 rounded text-columbia_blue-900 p-2 border-dutch_white-100 border-2'}><p className={'font-bold'}>Warning:</p> Images are publicly hosted. please do not upload images of students or
                                otherwise expose sensitive information.</p>

                        </div>
                    </div>
                    <div className={"col-span-4 flex flex-col mx-2"}>
                        <div className={'editor-container my-1'}>
                        <TipTap setHtmlOutput={setEditorCorrectHtml} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-columbia_blue-200'} placeholder={'Correct answer...'} />

                            {/*<Editor*/}
                            {/*    id={'correct'}*/}
                            {/*    ref={newQuillRef}*/}
                            {/*    readOnly={false}*/}
                            {/*    placeholder={'Correct:'}*/}
                            {/*    backgroundColor={'#d8e6ec'}*/}
                            {/*    onTextChange={handleEditorCorrectChange}*/}
                            {/*/>*/}
                        </div>
                        <div className={'editor-container my-1'}>
                            <TipTap setHtmlOutput={setEditorDistractor1Html} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-burnt_sienna-100'} placeholder={'Distractor 1...'} />

                            {/*<Editor*/}
                            {/*    id={'distractor1'}*/}
                            {/*    ref={newQuillRef}*/}
                            {/*    readOnly={false}*/}
                            {/*    placeholder={'Distractor 1:'}*/}
                            {/*    backgroundColor={'#f8e2d9'}*/}
                            {/*    onTextChange={handleEditorDistractor1Change}*/}
                            {/*/>*/}
                        </div>
                        <div className={'editor-container my-1'}>
                            <TipTap setHtmlOutput={setEditorDistractor2Html} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-burnt_sienna-100'} placeholder={'Distractor 2...'} />

                            {/*<Editor*/}
                            {/*    id={'distractor2'}*/}
                            {/*    ref={newQuillRef}*/}
                            {/*    readOnly={false}*/}
                            {/*    placeholder={'Distractor 2:'}*/}
                            {/*    backgroundColor={'#f8e2d9'}*/}
                            {/*    onTextChange={handleEditorDistractor2Change}*/}
                            {/*/>*/}
                        </div>
                        <div className={'editor-container my-1'}>
                            <TipTap setHtmlOutput={setEditorDistractor3Html} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-burnt_sienna-100'} placeholder={'Distractor 3...'} />

                            {/*<Editor*/}
                            {/*    id={'distractor3'}*/}
                            {/*    ref={newQuillRef}*/}
                            {/*    readOnly={false}*/}
                            {/*    placeholder={'Distractor 3:'}*/}
                            {/*    backgroundColor={'#f8e2d9'}*/}
                            {/*    onTextChange={handleEditorDistractor3Change}*/}
                            {/*/>                        */}
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={publishTraditionalMCItem} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">
                <p>Publish Item</p>
            </button>
        </>
    );
}

export default TraditionalMcInput;